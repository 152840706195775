@import './shared/variables';

@import './shared/header.less';

@import './shared/fonts';
@import './shared/login';
@import './shared/matrix';

html,
body {
  margin: 0;
  font-family: 'Gotham';
  height: 100%;
}

.modal-backdrop {
  z-index: 9999;
}

.dashboardBoxes {
  width: 48%;
  height: 310px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 8px !important;

  &:nth-child(1) {
    background-color: extract(@dash-cards-Bg, 1);
  }

  &:nth-child(2) {
    background-color: extract(@dash-cards-Bg, 2);
  }

  &:nth-child(3) {
    background-color: extract(@dash-cards-Bg, 3);
  }

  &:nth-child(4) {
    background-color: extract(@dash-cards-Bg, 4);
  }

  .card-header {
    table {
      tbody {
        td {
          width: 50%;

          h2 {
            font-size: 22px;
            margin: 0px;
          }
        }
      }

      .HeaderSmall {
        font-size: 12px;
      }
    }
  }

  .card-body {
    table {
      tbody {
        tr.topLiveRate {
          td {
            width: 50%;

            h4 {
              font-size: 14px;
              margin: 16px 10px 4px 0px;
              color: #4c51c9;

              span {
                background-color: #dbec71;
                color: #000;
              }
            }
          }
        }

        tr.BtmDetails {
          td {
            width: 50%;

            h4 {
              font-size: 14px;
              margin: 16px 10px 4px 0px;
              color: #4c51c9;

              // text-transform: capitalize;
              p {
                margin: 9px 0px 4px 1px;
                color: #121212;
                font-size: 26px;
                font-weight: bold;
              }
            }
          }
        }
      }

      .AmountNseBse {
        color: #2eae32;
        font-size: 26px;
        font-weight: bold;
        margin: 10px 10px 4px 0px;

        span {
          font-weight: normal;
          font-size: 12px;
        }
      }

      .HeaderSmall {
        font-size: 12px;
      }
    }
  }

  .card-footer {
    padding: 9px 0px 4px 10px;

    button {
      width: 120px;
      height: 30px;
      background-color: #3338ae;
      color: #fff;
      font-size: 14px;
      padding: 4px 16px;
      border: 0px solid #000;
      border-radius: 3px;
    }
  }
}

.newsPanel {
  background-color: #dadbe5;
  height: 600px;
  width: 100%;
  padding: 10px;

  h6 {
    margin: 10px 0px;
    border-bottom: 1px solid #fff;
  }
}

.titleFilter {
  display: flex;
  justify-content: center;
  align-items: center;

  h4 {
    margin: 0px;
    font-size: 20px;
    font-weight: 400;
    width: 50%;
  }

  .filterMain {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    span {
      border-radius: 5px;
      border: 1px solid #ccc;
      padding: 5px 10px;
      vertical-align: middle;
      margin: 0px 5px;
    }

    div {
      // height: 20px;
      .basic-multi-select {
        //width: 16vw;

        .select__control {
          min-height: 30px;

          .select__value-container {
            padding: 0px 2px;

            .select__multi-value {
              border-radius: 5px;

              .select__multi-value__label {
                font-size: 70%;
              }
            }

            .select__input-container {
              // display: none;
            }
          }

          .select__indicators {
            .select__indicator {
              padding: 4px;
            }

            span.select__indicator-separator {
              // display: none;
            }
          }
        }
      }
    }
  }
}

.popup {
  position: fixed;
  left: 84%;
  top: 44%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background: white;
  border: 1px solid #ccc;
  z-index: 1000;
  /* Ensure it's above other content */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  width: 300px;
}

.Toastify__toast-container {
  z-index: 200000;
}
