@import '../../../styles/shared/variables.less';

@cmp-name: ~'cmp-toggle-switch';

.@{cmp-name} {
  position: relative;
  width: @toggle-width;
  height: @toggle-height;
  background: #0a418b38;
  border-radius: (@toggle-height / 2);
  cursor: pointer;
  transition: background @transition-ease;
  font-family: @gotham-medium;

  &__knob {
    position: absolute;
    top: @toggle-spacing-small;
    width: 78px;
    height: @knob-height;
    background: @knob-bg;
    border-radius: @knob-radius;
    transition: all @transition-ease;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: @knob-font-size;
    font-weight: @knob-font-weight;
    color: @knob-font-color;
  }

  &__knob--yearly {
    left: @toggle-spacing-small;
    background: @knob-active-bg;
    color: @knob-active-color;
  }

  &__knob--quarterly {
    left: calc(100% - 85px);
    background: @knob-active-bg;
    color: @knob-active-color;
  }

  &__label-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 89%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    pointer-events: none;
  }

  &__label {
    font-size: @knob-font-size;
    font-weight: @knob-font-weight;
    transition: color @transition-ease;
  }

  &__label--inactive {
    color: lighten(@knob-font-color, 40%);
  }
}
