@cmp-name: ~'cmp-app';

.@{cmp-name} {
  // width: 1440px;
  //margin: 0 auto;

  @media (max-width: 480px) {
    .example1 {
      //background-color: red;
      min-width: 320px;
      width: 100%;
      max-width: 480px;
      margin: 0 auto;
    }
  }

  /* Tablets */
  @media (min-width: 481px) and (max-width: 768px) {
    .example1 {
      //background-color: blue;
      max-width: 725px;
      width: 100%;
      margin: 0 auto;
    }
  }

  /* Laptops */
  @media (min-width: 769px) and (max-width: 1024px) {
    .example1 {
      //background-color: orange;
      max-width: 900px;
      width: 100%;
      margin: 0 auto;
    }
  }

  /* Desktops */
  @media (min-width: 1025px) {
    .example1 {
      //background-color: pink;
      max-width: 1240px;
      width: 100%;
      margin: 0 auto;
    }
  }
}
