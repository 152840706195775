@import '../../../../styles/shared/variables.less';

@cmp-name: ~'cmp-cash-flow';

.@{cmp-name} {
  overflow-x: auto;
  width: 98%;

  &__navbar {
    width: 100%;
    height: 42px;
    border-bottom: 1px solid;
    display: flex;
    flex-direction: row;

    &-btn {
      font-family: @gotham-medium;
      border: @display-none;
      outline: @display-none;
      padding: 10px 16px;
      cursor: @cursor-pointer;
      font-size: @font-size-16;
      background-color: @white;

      &:hover {
        border-bottom: 3px @border-solid @blue;
        background-color: #e9f7ea;
      }

      &--active {
        @media (max-width: 585px) {
          line-height: 15px;
        }
        font-family: @gotham-bold;
        border: @display-none;
        outline: @display-none;
        padding: 10px 16px;
        cursor: @cursor-pointer;
        font-size: @font-size-16;
        background-color: @white;
        border-bottom: 3px @border-solid @blue;
        color: @max-blue-green;
      }
    }
  }

  &__body {
    overflow-x: auto;
    margin-top: 10px;
    border-radius: 8px;
    background-color: white;
    border: 0.5px solid #c5ccd4;
    padding: 10px;

    &::-webkit-scrollbar {
      width: 0.5em;
      height: 0.5em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #1c304b;
      outline: 1px solid #1c304b;
      border-radius: 50px;
    }

    &_table {
      text-align: right;
      //border: 0.5px solid #C5CCD4;
      border-radius: 8px;
      //border-collapse: separate;
      border-spacing: 0;
      font-size: @font-size-12;
      border-collapse: separate;
      border: 1px solid #ccc;
      font-weight: 100;
      border-radius: @border-radius-8;

      &-thead {
        background-color: @max-blue-green;
        border-radius: 8px 8px 0 0;

        th {
          padding: 10px;

          &:first-child {
            //width: 350px;
            min-width: 140px;
            text-align: left;
            border-radius: 8px 0 0 0;
          }

          &:last-child {
            min-width: 100px;
            border-radius: 0 8px 0 0;
          }
        }
      }

      &-tbody {
        tr {
          height: 45px;

          td {
            padding: 5px;
            border-bottom: 0.5px solid #c5ccd4;

            &:first-child {
              text-align: left;
            }
          }
        }

        &-label {
          width: 350px;
        }

        tr:last-child {
          td:first-child {
            border-radius: 0 0 0 8px;
          }

          td:last-child {
            border-radius: 0 0 8px 0;
          }
        }
      }
    }
  }

  &__control {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;

    &--cancel {
      font-size: 14px;
      margin: 10px;
      background-color: white;
      border: 0.5px solid #3338ae;
      padding: 8px;
      border-radius: 3px;

      &:hover {
        background-color: @midnight-blue;
        color: white;
        font-weight: 600;
      }
    }

    &--continue {
      font-size: 14px;
      margin: 10px;
      background-color: @space-cadet;
      border: 0.5px solid #3338ae;
      padding: 8px;
      color: white;
      font-weight: 600;
      border-radius: 3px;

      &:hover {
        background-color: @midnight-blue;
        color: white;
      }
    }
  }
}

.error input {
  border: 1px solid red;
}

.error-message {
  color: red;
  font-size: 0.8em;
  margin-top: 4px;
  position: absolute;
  background-color: white;
  border: 1px solid red;
  padding: 4px;
  display: none;
}

.error:hover .error-message {
  display: block;
}

input {
  margin: 4px;
  box-sizing: border-box;
  width: 76px;
  padding: 6px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid @max-blue-green;
}

label {
  margin-right: 10px;
}

.cashflow__footer {
  display: flex;
  flex-direction: row;
  padding: 25px;
  border: 0.5px solid #c5ccd4;
  margin: 10px 0px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
}
