@import '../../styles/shared/variables.less';

@cmp-name: ~'cmp-search-action';

.@{cmp-name} {
  display: @display-flex;
  justify-content: @align-center;
  align-items: @align-center;
  margin: 15px;

  &__container {
    &_title {
      display: @display-flex;
      justify-content: @align-center;

      &-text {
        font-family: @gotham-medium;
        font-size: calc(@font-size-6 + 1vw); //@font-size-18;
      }
    }

    &_control {
      display: @display-flex;
      flex-direction: row;
      align-items: @align-center;
      padding-top: 10px;

      @media only screen and (min-width: 10px) and (max-width: 991px) {
        gap: 10px;
        flex-direction: column;
      }

      &-btn {
        padding-left: 21px;
        display: flex;
        padding-right: 21px;

        &-compare {
          color: #fff;
          background-color: @space-cadet;
          border-radius: @border-radius-25;
          width: 101px;
          height: 30px;
          padding: 4px 16px;
          font-size: @font-size-16;
          margin-right: 10px;
          border: @display-none;
        }
        &-valuation {
          color: #fff;
          background-color: @space-cadet;
          border-radius: @border-radius-25;
          width: 101px;
          height: 30px;
          padding: 4px 16px;
          font-size: @font-size-16;
          border: @display-none;
        }

        &-compare:hover,
        &-valuation:hover {
          background-color: @midnight-blue;
          box-shadow: @box-shadow;
        }
      }
    }
  }
}
