@import '../../styles/shared/variables.less';
@cmp-name: ~'cmp-breadcrumbs';

.@{cmp-name} {
  //width: 1440px;
  //margin: 0 auto;

  &_nav {
    padding: 0px 0px 8px 0px;
    border-bottom: 0.5px solid #dadbe5;
    margin-bottom: 8px;
    text-transform: uppercase;
    font-family: @gotham-medium;
    &-span {
      &-links {
        font-size: 12px;
        color: #696969;
        padding: 10px;
        text-decoration: none;
      }
      &-img {
        width: 8px;
        height: 10px;
      }
    }
  }
}
