@import '../../styles/shared/variables.less';
@cmp-name: ~'cmp-hero-banner';

.@{cmp-name} {
  //width: 1440px;
  //margin: 0 auto;
  &__banner {
    color: @white;

    &-title {
      //font-size: @font-size-34 ;
      //font-size: max(calc(@font-size-12 + 1vw),34px) ;
      font-size: clamp(12px, calc(@font-size-12 + 1vw), 34px);

      font-family: @gotham-bold;
      padding: 0 10px;
      margin: 0;
      word-wrap: break-word;
      word-break: break-all;
      white-space: normal;
    }

    &-subtitle {
      text-align: @align-center;
      //font-size: @font-size-16;
      font-family: @gotham-book;
      font-size: max(calc(4px + 1vw), 16px);
      width: calc(50.9% + 1vw);
    }
  }

  &__container {
    &--img {
      width: calc(98.9% + 1vw);
    }

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (min-width: 100) and (max-width: 767px) {
      &__banner {
        padding: 0 10px;
      }
      &--img {
        height: 200px;
      }
    }
  }
}
