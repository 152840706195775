@import '../shared/variables.less';

@cmp-name: ~'cmp-header';

.@{cmp-name} {
  &__container {
    //width: @xxl;
    //margin: 0 auto;
  }

  &__main {
    display: @display-flex;
    flex-direction: @display-flex-row;
    justify-content: @content-space-between;
    //height: 50px;
    padding: 8px;
    flex-wrap: wrap;
  }

  &__logo {
    display: @display-flex;
    flex-direction: @display-flex-row;
    align-items: @align-center;

    &_container {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      &--infra {
        width: 100px;
      }

      &--seprator {
        width: 0.2px;
        height: 60px;
        border: 0.1px solid #ccc;
        margin-left: 10px;
      }

      &--company {
        width: 190px;
      }
    }

    &--title {
      font-family: @gotham-bold;
      margin-left: 10px;
      font-size: @font-size-16;
      color: @space-cadet;
      letter-spacing: 0;
    }
  }

  &__contact-button {
    background-color: #0c2d48;
    color: @white;
    text-decoration: none;
    padding: 8px 20px;
    border-radius: 25px;
    font-family: @gotham-medium;
    font-size: @font-size-12;
    font-weight: @font-weight-500;
    display: inline-block;
    cursor: pointer;
    &:hover {
      background-color: #189ab4;
      color: @white;
      box-shadow: @box-shadow;
    }
  }

  &__login {
    display: @display-flex;
    flex-direction: @display-flex-row;
    align-items: @align-center;

    &--mail {
      font-family: @gotham-medium;
      color: @space-cadet;
      font-weight: @font-weight-400;
      font-size: @font-size-16;
      padding-right: 10px;
    }

    .login-button {
      //font-size: @font-size-14;
      padding: 0px 20px;
      background-color: @space-cadet;
      color: @white;
      height: 35px;
      margin: 8px 0;
      border: @display-none;
      cursor: @cursor-pointer;
      border-radius: @filter-border-radius-25;
    }

    .login-close {
      background-color: @space-cadet;
      color: @white;
      height: 35px;
      margin: 8px 0;
      border: @display-none;
      cursor: @cursor-pointer;
      border-radius: @filter-border-radius-25;
      padding: 0px 20px;
    }

    .login-close:hover,
    .login-button:hover {
      background-color: @midnight-blue;
      box-shadow: @box-shadow;
    }
  }

  &__hamburger-menu {
    display: none;

    @media (max-width: 767px) {
      display: block;
    }
  }
}

@media (max-width: 767px) {
  .@{cmp-name}__login {
    display: none; // Hide login section on small screens
  }

  .hamburger-menu__user-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    &--username {
      font-family: @gotham-medium;
      color: @space-cadet;
      font-weight: @font-weight-400;
      font-size: @font-size-14;
    }

    &--logout {
      background-color: @space-cadet;
      color: @white;
      padding: 8px 16px;
      border-radius: @filter-border-radius-25;
      cursor: pointer;

      &:hover {
        background-color: @midnight-blue;
        box-shadow: @box-shadow;
      }
    }
  }
}
