@primary-color: #007bff;
@secondary-color: #6c757d;
@background-color: #f5f5f5;
@text-color: #666;

.paper {
  padding: 5px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.title {
  font-size: 24px;
  margin-bottom: 10px;
}

.description {
  font-size: 16px;
  color: @text-color;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .input {
    padding: 10px 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }

  .btn {
    padding: 10px 15px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    color: #fff;

    &.primary {
      background-color: @primary-color;
    }

    &.secondary {
      background-color: @secondary-color;
    }

    &:hover {
      opacity: 0.9;
    }
  }
}
