@font-family: 'Arial, sans-serif';
@background-color: #f4f4f9;
@input-border: #ddd;
@disabled-color: #ccc;

@import '../../../styles/shared/variables.less';

// body {
//   font-family: @font-family;
//   background-color: #f9f9f9;
//   margin: 0;
//   padding: 0;
// }

.otp-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  //   height: 100vh;
  background-color: @background-color;

  .otp-form {
    background: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    box-shadow: 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;

    h2 {
      text-align: center;
      color: #333;
      margin-bottom: 20px;
    }

    .form-group {
      margin-bottom: 15px;

      label {
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
        color: #333;
      }

      input {
        width: 100%;
        padding: 10px;
        border: 1px solid @input-border;
        border-radius: 4px;
        font-size: 16px;
      }
    }

    .btn-submit {
      display: block;
      width: 100%;
      padding: 10px;
      background-color: @space-cadet;
      color: #fff;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      cursor: pointer;

      &:hover {
        background-color: @btn-blue-hover;
      }

      &:disabled {
        background-color: @disabled-color;
        cursor: not-allowed;
      }
    }
  }
  .btn-resend {
    margin-top: 10px;
    padding: 10px 15px;
    font-size: 16px;
    color: #fff;
    background-color: @space-cadet;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .btn-resend:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .btn-resend:hover:not(:disabled) {
    background-color: #0056b3;
  }

  .resend-section {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .timer {
    margin-left: 10px;
    position: relative;
    font-size: 14px;
    color: #333;
    padding: 10px;
    margin-top: 6px;

    .circle {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: linear-gradient(135deg, #05445e, #0077b6);
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      //   animation: pulse 1s infinite alternate;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(5, 68, 94, 0.5);
  }
  100% {
    transform: scale(1.1);
    box-shadow: 0 0 20px rgba(5, 68, 94, 0.8);
  }
}
