@import '../../styles/shared/variables.less';
@cmp-name: ~'cmp-portfolio';

.@{cmp-name} {
  //width: 1440px;
  //margin: 0 auto;

  &__main {
    display: flex;
    flex-direction: row;
    margin: 10px 16px;

    &__allmetrics {
      width: 70%;
      // margin-bottom: 20px;
      // padding-right: 10px;
      max-width: 70%;

      &--compare {
        display: none;
      }
    }

    &__Cards {
      width: 30%;
      position: sticky;
    }

    @media only screen and (min-width: 100px) and (max-width: 599px) {
      .@{cmp-name} {
        overflow-x: auto;
      }

      &__allmetrics {
        width: 100%;
        max-width: 100%;
      }

      &__Cards {
        display: flex;
        width: 100%;
      }
    }
  }

  .tableHeader {
    margin-top: 16px;
    border: 0.5px solid #c5ccd480;

    span {
      color: #000;
      // text-align: right;
      background-color: #e9f7ea;
      width: 98.4%;
      font-size: 16px;
      font-weight: bold;
      display: block;
      padding: 8px; //border: 0.5px solid #C5CCD480;
    }

    // .tableGrid {
    //   width: 100%;
    //   // height: 200px;
    //   overflow-x: auto;
    //   border-collapse: collapse;
    //   border: 1px solid #ccc;
    //   overflow-y: auto;

    //   th {
    //     background-color: #dadbe5;
    //     height: 27px;
    //     padding: 10px;
    //     font-size: 12px;
    //     border: none;
    //     font-weight: normal;
    //     text-align: left;
    //     padding-left: 15px;
    //     font-weight: 600;
    //   }

    //   .location-header td {
    //     font-weight: normal;
    //     color: #121212;
    //     background-color: #e9fcf9;
    //     text-align: left;
    //   }

    //   td {
    //     background-color: #fff;
    //     border-bottom: 1px solid #ccc;
    //     padding: 12px;
    //     font-size: 14px;

    //     &:first-child {
    //       width: 35%;
    //     }
    //   }

    //   .subMaintitle {
    //     background-color: #c5faf1;
    //     font-size: 14px;
    //     font-weight: bold;
    //   }

    //   .subtitle {
    //     background-color: #e9f7ea;
    //     font-size: 14px;
    //     font-weight: bold;
    //   }

    //   .reitsName {
    //     th {
    //       text-align: center;
    //       font-size: 14px;
    //       font-weight: bold;
    //       height: 80px;

    //       &:nth-child(1) {
    //         background-color: transparent;
    //         width: 30%;
    //       }

    //       &:nth-child(2) {
    //         background-color: extract(@reits-logo-Bg, 1);
    //       }

    //       &:nth-child(3) {
    //         background-color: extract(@reits-logo-Bg, 2);
    //       }

    //       &:nth-child(4) {
    //         background-color: extract(@reits-logo-Bg, 3);
    //       }

    //       &:last-child {
    //         width: 10%;
    //         background-color: #fff;
    //       }
    //     }

    //     .addReits {
    //       div {
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //       }
    //     }

    //     .close {
    //       width: 10%;
    //       padding: 1px;
    //       position: relative;
    //       left: 89%;
    //       top: -25px;
    //     }
    //   }

    //   td.reitsLastCol {
    //     border-left: 1px solid #dadbe5;
    //     border-right: 1px solid #dadbe5;
    //   }
    // }
  }
}

.orangeLight {
  background-color: #faf6e4;
}

.purpleLight {
  background-color: #f7ecfd;
}

.width35 {
  width: 35%;
}

.width25 {
  width: 25%;
}

.width10 {
  width: 10%;
}

.width15 {
  width: 15%;
}
