@import './../../../../styles/shared/variables.less';

@cmp-name: ~'cmp-all-metrics';

.@{cmp-name} {
  display: @display-flex;
  flex-direction: @display-flex-column;

  &_section {
    margin-top: 16px;
    border: 1px solid #c5ccd480;
    display: @display-flex;
    flex-direction: @display-flex-column;
    justify-content: @align-center;
    border-radius: @border-radius-8;

    &__foonote {
      font-size: 12px;
      display: flex;
      padding-left: 10px;
      padding-top: 10px;
    }

    &__title {
      background-color: @max-blue-green;
      padding: 14px;
      border-radius: @border-radius-8 @border-radius-8 0 0;

      &_name {
        font-family: @gotham-bold;
        font-size: @font-size-16;
        color: @black;
      }
    }

    &__tbl {
      .tableGrid {
        width: 100%;
        overflow-x: auto;
        border-top: 0.5px solid #ccc;
        overflow-y: auto;
        border-collapse: separate;
        border-spacing: 0;

        th {
          font-family: @gotham-medium;
          background-color: @light-cyan;
          padding: 14px;
          font-size: @font-size-16;
          text-align: left;
        }

        tr:last-child {
          td:first-child {
            border-radius: 0 0 0 8px;
          }

          td:last-child {
            border-radius: 0 0 8px 0;
          }
        }

        td {
          background-color: #fff;
          border-bottom: 1px solid #ccc;
          padding: 12px;
          font-size: 14px;

          &:first-child {
            width: 30%;
          }
        }

        @media only screen and (min-width: 100px) and (max-width: 599px) {
          th {
            padding: 12px;
            font-size: @font-size-14;
          }

          td {
            padding: 10px;
            font-size: 12px;

            &:first-child {
              width: 25%;
            }
          }
        }

        .location-header td {
          font-family: @gotham-medium;
          color: #121212;
          background-color: #dadbe5;
          text-align: left;
        }
      }
    }
  }

  &__filter-header {
    display: @display-flex;
    justify-content: space-between;
    align-items: @align-center;
    margin-top: 18px;
    padding: 0px 10px;
  }

  &__filter-text {
    font-family: @gotham-medium;
    //font-size: @font-size-14;
    padding-right: 13px;
    font-weight: @font-weight-600;
  }

  &__filter-container {
    display: @display-flex;
    align-items: @align-center;
  }

  &__filter-main {
    display: @display-flex;
    align-items: @align-center;
    justify-content: @display-flex-end;
    gap: 10px;
    flex-direction: row;

    .css-1u9des2-indicatorSeparator {
      display: none;
    }

    .css-894a34-indicatorSeparator {
      display: none;
    }

    .css-1p3m7a8-multiValue {
      font-family: @gotham-medium;
      background-color: @light-cyan;
    }

    .css-1dimb5e-singleValue {
      padding: 1px;
      font-family: @gotham-medium;
      background-color: @light-cyan;
    }

    span {
      border-radius: @filter-border-radius;
      border: 1px @filter-border-solid @filter-border-color;
      padding: 5px 10px;
      vertical-align: @align-middle;
      margin: 0px 5px;
    }

    .css-1xc3v61-indicatorContainer:first-child {
      display: none;
    }

    .css-1hb7zxy-IndicatorsContainer:first-child {
      display: none;
    }

    div {
      &__basic_multi_select {
        width: 16vw;

        &__control {
          min-height: 30px;

          &__value-container {
            padding: 0px 2px;

            &__multi-value {
              border-radius: @filter-border-radius;

              &__label {
                font-size: 70%;
              }
            }

            &__input-container {
              display: @display-none;
            }
          }

          &__indicators {
            &__indicator {
              padding: 4px;
            }

            &__separator {
              display: @display-none;
            }
          }
        }
      }
    }
  }
}
