.contact-form-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100vh;
}

.image-section {
  background-image: url(../../assets/map-banner.png);
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.form-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-form {
  color: #000;
  background: #fff;
  border: 2px solid #000;
  border-radius: 8px;
  width: 25vw;
  padding: 30px;
}

.contact-form h2 {
  margin-bottom: 20px;
  color: black;
}

.contact-form .form-group {
  position: relative;
  margin-bottom: 15px;
}

.captcha {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
}

.contact-form label {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 1em;
  color: black;
  transition: all 0.3s ease;
  pointer-events: none;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px 0; /* Padding on top and bottom, none on left and right */
  border: none; /* Remove border from all sides */
  border-bottom: 2px solid #ddd; /* Only bottom border */
  font-size: 1em;
  color: black;
  background-color: transparent; /* Makes sure the background is transparent */
  outline: none; /* Removes the outline on focus */
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-bottom: 2px solid #189ab4; /* Changes the bottom border color on focus */
}

.contact-form input:focus ~ label,
.contact-form input:not(:placeholder-shown) ~ label,
.contact-form textarea:focus ~ label,
.contact-form textarea:not(:placeholder-shown) ~ label {
  top: -10px; /* Move label upward */
  font-size: 0.8em; /* Reduce font size */
  color: #189ab4; /* Change label color */
}

.contact-form textarea {
  resize: none;
  min-height: 40px;
}

.contact-form .message-limit {
  text-align: right;
  font-size: 0.8em;
  color: #ccc;
}

.contact-form button {
  width: 100%;
  padding: 12px;
  font-size: 1em;
  color: white;
  background-color: #10304e;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #1c7c91;
}

.left-section {
  color: #fff;
  background-color: #1b314c;
  min-width: 30vw;
  min-height: 30vh;
}

.content {
  margin-left: 30px;
}

.first-header {
  padding: 20px 0;
}

.mail-contact {
  display: flex;
  align-items: center;
  margin-top: 3px;
  padding: 5px;
}

.mail-contact .contact-icon {
  padding-left: 30px;
}

.mail-contact a {
  padding: 5px;
  text-decoration: none;
  color: white;
  font-size: 18px;
  font-weight: 500;
}

.contact-icons {
  padding-left: 50px;
}

.short-text {
  font-size: 12px;
}
