@import '../../styles/shared/variables.less';

@cmp-name: ~'cmp-evaluation';

.@{cmp-name} {
  //width: 1440px;
  //margin: 0 auto;
  font-weight: normal;
  &__search {
    display: @display-flex;
    justify-content: @display-flex-start;
    padding-left: 1rem;
  }
  &__valuation {
    padding: 1rem;
  }
}
