@import '../../styles/shared/variables.less';

@cmp-name: ~'cmp-hamburger';

.@{cmp-name} {
  position: relative;
  z-index: 10;

  &__container {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    cursor: pointer;

    &-burger {
      width: 2rem;
      height: 0.25rem;
      border-radius: 10px;
      background-color: @space-cadet;
      transition: all 0.3s linear;
    }
  }

  &__content {
    position: absolute;
    top: 2.5rem; // Position below the hamburger icon
    right: 0;
    background-color: @white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 8px;
    z-index: 10; // Ensure it's below the hamburger icon

    &-user {
      font-family: @gotham-medium;
      font-size: @font-size-14;
      margin-bottom: 10px;
      color: @space-cadet;
    }

    &-logout,
    &-login {
      background-color: @space-cadet;
      color: @white;
      padding: 8px 8px;
      border: none;
      border-radius: 8px;
      cursor: pointer;

      .login-button {
        padding: 0px 5px;
        background-color: @space-cadet;
        color: @white;
        height: 5px;
        // margin: 1px 0;
        border: @display-none;
        cursor: @cursor-pointer;
        border-radius: @filter-border-radius-25;
      }

      &:hover {
        background-color: @midnight-blue;
      }
    }
  }
}

@media (max-width: 767px) {
  .hamburger {
    display: flex;
  }
}
