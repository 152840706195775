@import '../../../styles/shared/variables.less';

@cmp-name: ~'cmp-card-layout';

.@{cmp-name} {
  width: @card-width;
  //border: 0.5 solid @keppel;
  border-radius: @border-radius-12;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  margin: 8px !important;
  padding: 10px;
  background-color: @bubbles;

  @media (max-width: 480px) {
    width: 90%;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media (min-width: 481px) and (max-width: 768px) {
    width: 90%;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media (min-width: 769px) and (max-width: 900px) {
    width: 90%;
  }

  @media (min-width: 900px) {
    width: 46%;
  }

  // &:nth-child(1) {
  //   background-color: extract(@dash-cards-Bg, 1);
  // }

  // &:nth-child(2) {
  //   background-color: extract(@dash-cards-Bg, 2);
  // }

  // &:nth-child(3) {
  //   background-color: extract(@dash-cards-Bg, 3);
  // }

  // &:nth-child(4) {
  //   background-color: extract(@dash-cards-Bg, 4);
  // }

  &__header {
    padding: 0 14px;

    &-table {
      width: 100%;
      padding-left: 1px;
      padding-top: 3px;
      margin-bottom: 8px;
    }

    &-tr {
      width: 50%;

      &--reitname {
        font-family: @gotham-bold;
        font-weight: @font-weight-700;
        font-size: @font-size-22;
        color: @midnight-blue;
      }

      &--location {
        font-size: @font-size-12;
        display: @display-flex;
        flex-wrap: @display-flex-wrap;
        width: 95%;
        color: @chinese-black;
      }

      &--date {
        font-size: @font-size-12;
        text-align: @align-right;
        color: @chinese-black;
      }
    }
  }

  &__hr {
    margin: 0px 15px;
  }

  &__body {
    padding: 0 12px;

    &-table {
      width: 100%;
      padding: 3px 0 0 1px;

      &-tbody {
        &-topdetails {
          &-head {
            display: @display-flex;
            justify-content: @display-flex-start;
            align-items: @align-center;
            margin: 10px 0px;

            &--title {
              font-family: @gotham-bold;
              font-size: @font-size-14;
              margin: 0px 5px 0px 0px;
              color: @auroMetalSaurus;
            }

            &--live {
              font-family: @gotham-bold;
              font-size: @font-size-10;
              background-color: @celadon-green;
              color: @white;
              padding: 2px;
            }
          }

          &-exchange {
            color: @celadon-green;
            margin: 10px 0px;
            width: 50%;
            display: @display-flex;
            align-items: @align-center;

            &--bse {
              font-family: @gotham-bold;
              font-family: @font-size-34;
              color: @chinese-black;
            }

            &-arrow {
              font-size: @font-size-12;
              margin-left: 4px;
              font-family: @gotham-medium;

              &--positive {
                color: #5ad05a;
              }

              &--negative {
                color: red;
              }

              &--neutral {
                color: @black;
              }
            }
          }
        }

        &-btmdetails {
          &-cell {
            width: 50%;

            &--title {
              font-family: @gotham-medium;
              font-size: @font-size-14;
              color: @auroMetalSaurus;
              text-transform: uppercase;
            }

            &--value {
              font-family: @gotham-bold;
              margin: 1px 0 4px 1px;
              color: @chinese-black;
              font-size: calc(12% +1vw);
              margin: 10px 0px;
            }
          }
        }
      }
    }
  }

  &__footer {
    padding: 9px 0px 4px 10px;

    &-btn {
      width: 120px;
      height: 30px;
      background-color: @max-blue-green;
      color: @white;
      font-size: @font-size-16;
      padding: 4px 16px;
      border: @display-none;
      border-radius: @border-radius-25;
      cursor: @cursor-pointer;
    }

    &-btn:hover {
      background-color: @myrtle-green;
      box-shadow: @box-shadow;
    }
  }
}
