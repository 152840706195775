.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000090;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
}

.modal-content {
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  max-width: 800px;
  min-width: 300px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
}

.close-button {
  float: right;
  font-size: 24px;
  font-weight: bold;
  border: none;
  background: none;
  padding: 0px;
}
