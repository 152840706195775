@import '../../../../styles/shared/variables.less';
@cmp-name: ~'cmp-compare-cards';

.@{cmp-name} {
  position: sticky;
  top: 10;
  margin: 10px;
  margin-top: 15px;

  @media only screen and (min-width: 100px) and (max-width: 599px) {
    display: flex;
    flex-direction: column;
    position: sticky;
  }

  &_title {
    font-family: @gotham-medium;
    font-size: @font-size-18;
    color: @dim-gray;
    padding-left: 5px;
  }

  &__card {
    padding: 20px;
    border-radius: @border-radius-8;
    margin: 8px 0px 8px;
    cursor: @cursor-pointer;
    background-color: @midnight-blue-38;
    @media only screen and (min-width: 100px) and (max-width: 599px) {
      display: flex;
      flex-direction: row;
    }

    &_body {
      display: @display-flex;
      flex-direction: @display-flex-column;

      &--name {
        font-family: @gotham-bold;
        font-size: @font-size-24;
        color: @black;
      }

      &--assets {
        margin-top: 5px;
        font-size: @font-size-12;
        color: @black;

        &--value {
          font-family: @gotham-bold;
        }
      }

      &--locations {
        font-size: @font-size-12;

        &--link {
          cursor: @cursor-pointer;
        }

        &--link:hover {
          text-decoration: @text-dec-line-underline;
        }
      }
    }
  }

  &_hr {
    width: 97%;
    color: @silver-chalice;
  }

  &_lockedPanel,
  &_lockedPanel2,
  &_lockedPanel3 {
    opacity: 0.89;
    text-align: @align-center;
    position: @position-absolute;
    width: 100%;
    display: @display-flex;
    align-content: @align-center;
    justify-content: @align-center;
    align-items: @align-center;
    background-color: #e9e9e9;
    border-radius: @border-radius-8;
    font-family: @gotham-bold;
    font-size: @font-size-16;

    &_div {
      width: 100%;
      display: @display-flex;
      flex-direction: @display-flex-row;
      justify-content: @align-center;
      align-items: @align-center;

      &_maintitle {
        width: 75%;
        display: @display-flex;
        flex-direction: @display-flex-column;
      }

      &_icon {
        width: 25%;
      }
    }

    &:hover {
      opacity: 0.89;
      background-color: #5f5e5e;
      border-radius: 1px solid #353434;
      cursor: @cursor-pointer;
      color: @white;
      font-family: @gotham-bold;
    }
  }

  &_lockedPanel {
    top: 26px;
    height: 342px;

    &_div &_maintitle {
      gap: 30px;
    }
  }

  &_lockedPanel2 {
    top: 415px;
    height: 86px;

    &_div &_maintitle {
      gap: 12px;
    }
  }

  &_lockedPanel3 {
    top: 550px;
    height: 85px;

    &_div &_maintitle {
      gap: 12px;
    }
  }
}
