.cmp-login {
  background: #fff;
  border-radius: 6px;
  width: 345px;
  height: 320px;
  padding: 0 20px;
}

.cmp-login__header {
  width: 345px;
  font-size: 12px;
}

.cmp-login__form {
  margin-top: 10px;
}

.cmp-login__field {
  width: 100%;
}

.cmp-login__field--shake {
  animation: .3s ease-in-out shake;
}

.cmp-login__field--error .cmp-login__input {
  border-color: #dc3545;
}

.cmp-login__field label {
  font-size: 12px;
  font-weight: 700;
}

@keyframes shake {
  0%, 100% {
    margin-left: 0;
  }

  20%, 80% {
    margin-left: -12px;
  }

  40%, 60% {
    margin-left: 12px;
  }
}

.cmp-login__input-area {
  width: 100%;
  height: 50px;
  position: relative;
}

.cmp-login__input {
  caret-color: #5372f0;
  background: none;
  border: 1px solid #bfbfbf;
  border-bottom-width: 2px;
  border-radius: 6px;
  outline: none;
  width: 345px;
  height: 35px;
  padding: 0 40px;
  font-size: 18px;
  transition: all .2s;
}

.cmp-login__input:hover, .cmp-login__input:focus {
  border-color: #5372f0;
}

.cmp-login__input::placeholder {
  color: #bfbfbf;
  font-size: 17px;
}

.cmp-login__icon {
  pointer-events: none;
  color: #bfbfbf;
  height: 35%;
  font-size: 18px;
  transition: color .2s;
  position: absolute;
  top: 17px;
  left: 15px;
  transform: translateY(-50%);
}

.cmp-login__error-txt {
  color: #dc3545;
  font-size: 12px;
  position: absolute;
  top: 37px;
  right: 5px;
}

.cmp-login__forget-password {
  text-align: right;
  color: #121212;
  margin-top: 5px;
  font-size: 12px;
}

.cmp-login__submit-btn {
  justify-content: center;
  display: flex;
}

.cmp-login__submit-btn .cmp-login__submit {
  color: #fff;
  cursor: pointer;
  background: #10304e;
  border: none;
  border-bottom: 2px solid #121212;
  border-radius: 4px;
  width: 98%;
  height: 34px;
  margin-top: 20px;
  transition: all .3s;
}

.cmp-login__submit-btn .cmp-login__submit:hover {
  background: #2c52ed;
}

.cmp-login__signup-text {
  text-align: center;
  font-size: 13px;
}

.cmp-login__link {
  color: #5372f0;
  text-decoration: none;
}

.cmp-login__link:hover {
  text-decoration: underline;
}

.paper {
  text-align: center;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  padding: 5px;
  box-shadow: 0 5px 15px #00000059;
}

.title {
  margin-bottom: 10px;
  font-size: 24px;
}

.description {
  color: #666;
  margin-bottom: 20px;
  font-size: 16px;
}

.form .input {
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
}

.form .btn {
  cursor: pointer;
  color: #fff;
  border: none;
  border-radius: 4px;
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
}

.form .btn.primary {
  background-color: #007bff;
}

.form .btn.secondary {
  background-color: #6c757d;
}

.form .btn:hover {
  opacity: .9;
}

.otp-form-container {
  background-color: #f4f4f9;
  justify-content: center;
  align-items: center;
  display: flex;
}

.otp-form-container .otp-form {
  box-shadow: 10px #0000001a;
  background: #fff;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  padding: 20px 30px;
}

.otp-form-container .otp-form h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.otp-form-container .otp-form .form-group {
  margin-bottom: 15px;
}

.otp-form-container .otp-form .form-group label {
  color: #333;
  margin-bottom: 5px;
  font-weight: bold;
  display: block;
}

.otp-form-container .otp-form .form-group input {
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  font-size: 16px;
}

.otp-form-container .otp-form .btn-submit {
  color: #fff;
  cursor: pointer;
  background-color: #10304e;
  border: none;
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  display: block;
}

.otp-form-container .otp-form .btn-submit:hover {
  background-color: #2c376b;
}

.otp-form-container .otp-form .btn-submit:disabled {
  cursor: not-allowed;
  background-color: #ccc;
}

.otp-form-container .btn-resend {
  color: #fff;
  cursor: pointer;
  background-color: #10304e;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px 15px;
  font-size: 16px;
  transition: background-color .3s;
}

.otp-form-container .btn-resend:disabled {
  cursor: not-allowed;
  background-color: #ccc;
}

.otp-form-container .btn-resend:hover:not(:disabled) {
  background-color: #0056b3;
}

.otp-form-container .resend-section {
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  display: flex;
}

.otp-form-container .timer {
  color: #333;
  margin-top: 6px;
  margin-left: 10px;
  padding: 10px;
  font-size: 14px;
  position: relative;
}

.otp-form-container .timer .circle {
  color: #fff;
  background: linear-gradient(135deg, #05445e, #0077b6);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-weight: bold;
  display: flex;
}

@keyframes pulse {
  0% {
    transform: scale(1.1);
    box-shadow: 0 0 10px #05445e80;
  }

  100% {
    transform: scale(1.1);
    box-shadow: 0 0 20px #05445ecc;
  }
}

.cmp-signup {
  background: #fff;
  border-radius: 6px;
  width: 345px;
  height: 450px;
  padding: 0 20px;
}

.cmp-signup__header {
  width: 345px;
  font-size: 12px;
}

.cmp-signup__form {
  margin-top: 10px;
}

.cmp-signup__field {
  width: 100%;
}

.cmp-signup__field--shake {
  animation: .3s ease-in-out shake;
}

.cmp-signup__field--error .cmp-signup__input {
  border-color: #dc3545;
}

.cmp-signup__field label {
  font-size: 12px;
  font-weight: 700;
}

.cmp-signup__input-area {
  width: 100%;
  height: 35px;
  margin-top: 0;
  margin-bottom: 10px;
  position: relative;
}

.cmp-signup__input {
  caret-color: #5372f0;
  background: none;
  border: 1px solid #bfbfbf;
  border-bottom-width: 2px;
  border-radius: 6px;
  outline: none;
  width: 345px;
  height: 35px;
  padding: 0 40px;
  font-size: 18px;
  transition: all .2s;
}

.cmp-signup__input:hover, .cmp-signup__input:focus {
  border-color: #5372f0;
}

.cmp-signup__input::placeholder {
  color: #bfbfbf;
  font-size: 17px;
}

.cmp-signup__icon {
  pointer-events: none;
  color: #bfbfbf;
  height: 35%;
  font-size: 18px;
  transition: color .2s;
  position: absolute;
  top: 20px;
  left: 15px;
  transform: translateY(-50%);
}

.cmp-signup__captcha {
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  display: flex;
}

.cmp-signup__error-txt {
  color: #dc3545;
  font-size: 12px;
  position: absolute;
  top: 40px;
  right: 0;
}

.cmp-signup__submit-btn {
  justify-content: center;
  display: flex;
}

.cmp-signup__submit-btn .cmp-signup__submit {
  color: #fff;
  cursor: pointer;
  background: #5372f0;
  border: none;
  border-bottom: 2px solid #121212;
  border-radius: 4px;
  width: 98%;
  height: 34px;
  margin-top: 8px;
  margin-bottom: 5px;
  transition: all .3s;
}

.cmp-signup__submit-btn .cmp-signup__submit:hover {
  background: #2c52ed;
}

.cmp-signup__submit-btn .cmp-signup__submit:disabled {
  background: gray;
}

.cmp-signup__login-text {
  text-align: center;
  margin-top: 2px;
  font-size: 13px;
}

.cmp-signup__link {
  color: #5372f0;
  text-decoration: none;
}

.cmp-signup__link:hover {
  text-decoration: underline;
}

.cmp-signup__pwdstrength {
  font-size: 10px;
  position: absolute;
  top: 214px;
  right: 588px;
}

.modal-backdrop {
  backdrop-filter: blur(4px);
  background-color: #00000090;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.modal-content {
  background-color: #fff;
  border-radius: 5px;
  min-width: 300px;
  max-width: 800px;
  padding: 20px;
}

.modal-header {
  justify-content: space-between;
  display: flex;
}

.close-button {
  float: right;
  background: none;
  border: none;
  padding: 0;
  font-size: 24px;
  font-weight: bold;
}

.cmp-hamburger {
  z-index: 10;
  position: relative;
}

.cmp-hamburger__container {
  cursor: pointer;
  flex-flow: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  display: flex;
}

.cmp-hamburger__container-burger {
  background-color: #10304e;
  border-radius: 10px;
  width: 2rem;
  height: .25rem;
  transition: all .3s linear;
}

.cmp-hamburger__content {
  z-index: 10;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  position: absolute;
  top: 2.5rem;
  right: 0;
  box-shadow: 0 4px 6px #0000001a;
}

.cmp-hamburger__content-user {
  color: #10304e;
  margin-bottom: 10px;
  font-family: Gotham-Medium;
  font-size: 14px;
}

.cmp-hamburger__content-logout, .cmp-hamburger__content-login {
  color: #fff;
  cursor: pointer;
  background-color: #10304e;
  border: none;
  border-radius: 8px;
  padding: 8px;
}

.cmp-hamburger__content-logout .login-button, .cmp-hamburger__content-login .login-button {
  color: #fff;
  cursor: pointer;
  background-color: #10304e;
  border: none;
  border-radius: 25px;
  height: 5px;
  padding: 0 5px;
}

.cmp-hamburger__content-logout:hover, .cmp-hamburger__content-login:hover {
  background-color: #0a418b;
}

@media (width <= 767px) {
  .hamburger {
    display: flex;
  }
}

.cmp-reit-banner_banner {
  background-color: #10304e;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0 0 10px #0000001a;
}

.cmp-reit-banner_banner__header {
  margin-top: 12px;
  margin-bottom: 8px;
}

.cmp-reit-banner_banner__header_tbl {
  width: 100%;
  padding: 0 15px;
}

@media only screen and (width >= 100px) and (width <= 599px) {
  .cmp-reit-banner_banner__header_tbl tbody tr {
    flex-wrap: wrap;
    display: flex;
  }
}

.cmp-reit-banner_banner__header_tbl_col1 {
  flex-direction: column;
  display: flex;
}

.cmp-reit-banner_banner__header_tbl_col1--name {
  color: #fff;
  font-family: Gotham-Bold;
  font-size: 24px;
}

.cmp-reit-banner_banner__header_tbl_col1--locations {
  color: #fff;
  margin: 10px 0;
  font-size: 12px;
}

.cmp-reit-banner_banner__header_tbl_col1--locations--more {
  font-family: Gotham-Medium;
}

.cmp-reit-banner_banner__header_tbl_col1--locations--more:hover {
  text-decoration: underline;
}

.cmp-reit-banner_banner__header_tbl_col2 {
  width: 10%;
}

.cmp-reit-banner_banner__header_tbl__caaplScore {
  display: none;
}

.cmp-reit-banner_banner__header_tbl_bse {
  margin-right: 15px;
}

.cmp-reit-banner_banner__header_tbl_bse_cont-title {
  align-items: center;
  display: flex;
}

.cmp-reit-banner_banner__header_tbl_bse_cont-title--name {
  color: #fff;
  font-family: Gotham-Bold;
  font-size: 14px;
}

.cmp-reit-banner_banner__header_tbl_bse_cont-title--live {
  color: #fff;
  background-color: #0a418b;
  margin-left: 5px;
  padding: 2px;
  font-family: Gotham-Medium;
  font-size: 12px;
}

.cmp-reit-banner_banner__header_tbl_bse_cont-subtitle-exchange {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.cmp-reit-banner_banner__header_tbl_bse_cont-subtitle-exchange-value {
  color: #2fbeb7;
  padding: 2px;
  font-family: Gotham-Bold;
  font-size: 26px;
}

.cmp-reit-banner_banner__header_tbl_bse_cont-subtitle-exchange-ticker {
  color: #45a6ac;
  font-family: Gotham-Medium;
  font-size: 12px;
}

.cmp-reit-banner_banner__header_tbl_primary {
  color: #e1f8f6;
}

.cmp-reit-banner_banner__header_tbl_primary-container {
  text-align: right;
}

@media only screen and (width >= 100px) and (width <= 599px) {
  .cmp-reit-banner_banner__header_tbl_primary-container {
    text-align: left;
  }
}

.cmp-reit-banner_banner__header_tbl_primary-container-assets--link {
  color: #fff;
  font-size: 12px;
}

.cmp-reit-banner_banner__header_tbl_primary-container-assets--link--hover {
  cursor: pointer;
  margin-left: 4px;
  font-family: Gotham-Medium;
}

.cmp-reit-banner_banner__header_tbl_primary-container-assets--link--hover:hover {
  text-decoration: underline;
}

.cmp-reit-banner_banner__header_tbl_primary-container-date {
  color: #fff;
  margin-top: 2px;
  font-size: 12px;
}

.cmp-reit-banner_banner__header_tbl_primary-container-date--name--time {
  font-family: Gotham-Medium;
}

.cmp-reit-banner_banner__body_flex {
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  display: flex;
}

.cmp-reit-banner_banner__body_tbl {
  width: 100%;
  padding: 0 15px;
}

@media only screen and (width >= 100px) and (width <= 599px) {
  .cmp-reit-banner_banner__body_tbl tbody tr {
    flex-wrap: wrap;
    display: flex;
  }

  .cmp-reit-banner_banner__body_tbl tbody tr td {
    margin-top: 8px;
    margin-right: 12px;
  }

  .cmp-reit-banner_banner__body_tbl tbody tr td :only-child {
    flex-grow: 12;
  }

  .cmp-reit-banner_banner__body_tbl tbody tr td :last-child {
    width: 200px;
  }
}

.cmp-reit-banner_banner__body_tbl-col1hr {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.cmp-reit-banner_banner__body_tbl-col1hr--hr {
  background: #fff;
  width: 100%;
  height: .2px;
}

.cmp-reit-banner_banner__body_tbl-title {
  color: #0e8bbf;
  font-family: Gotham-Bold;
  font-size: 14px;
}

.cmp-reit-banner_banner__body_tbl-value {
  color: #2fbeb7;
  white-space: pre-wrap;
  font-family: Gotham-Bold;
  font-size: 24px;
  line-height: 20px;
}

.cmp-reit-banner_banner__body_tbl-value-sponsors {
  font-family: Gotham-Bold;
  font-size: 18px;
}

.cmp-reit-banner_banner__body_tbl-value-sponsors-link {
  cursor: pointer;
  font-size: 14px;
}

.cmp-reit-banner_banner__body_tbl-value-sponsors-link:hover {
  color: #fff;
  text-decoration: underline;
}

.cmp-reit-banner_banner__foot {
  margin-bottom: 10px;
  padding: 0 15px;
}

.cmp-reit-banner_banner__foot-description {
  color: #e1f8f6;
  font-size: 12px;
}

.tooltip {
  text-align: center;
  pointer-events: none;
  opacity: .9;
  visibility: hidden;
  background: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
  width: auto;
  height: auto;
  padding: 8px;
  font: 12px sans-serif;
  position: absolute;
}

.axis line {
  stroke: #000;
  stroke-width: .5px;
}

@media (width <= 767px) {
  .bar-graph-container {
    white-space: nowrap;
    overflow-x: scroll;
  }
}

.cmp-time-series-chart {
  margin: 12px;
  padding-top: 10px;
  padding-bottom: 40px;
}

.cmp-time-series-chart__legend {
  justify-content: center;
  align-items: center;
  font-family: Gotham-Medium;
  display: flex;
}

.cmp-time-series-chart__circle {
  border-radius: 50%;
  width: 7px;
  height: 15px;
  margin-right: 10px;
  padding-right: 9px;
}

.cmp-time-series-chart__navbar {
  width: 100%;
  height: 47px;
}

.cmp-time-series-chart__navbar-btn {
  cursor: pointer;
  color: #696969;
  background-color: #fff;
  border: none;
  outline: none;
  padding: 10px 16px;
  font-family: Gotham-Bold;
}

.cmp-time-series-chart__navbar-btn:hover {
  background-color: #e9f7ea;
  border-bottom: 3px solid #3338ae;
}

.cmp-time-series-chart__navbar-btn--active {
  color: #3338ae;
  border-bottom: 3px solid #3338ae;
}

@media (width <= 767px) {
  .cmp-time-series-chart {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }

  .cmp-time-series-chart > div {
    min-width: 600px;
  }
}

.tooltip {
  pointer-events: none;
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  display: none;
  position: absolute;
}

.cmp-tool-tip__tooltip_wrapper {
  display: inline-block;
  position: relative;
}

.cmp-tool-tip__tooltip_button {
  color: #fff;
  cursor: pointer;
  background-color: gray;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  font-size: 10px;
  display: flex;
}

.cmp-tool-tip__tooltip_content {
  visibility: visible;
  color: #fff;
  text-align: center;
  z-index: 1;
  opacity: .9;
  background-color: #555;
  border-radius: 6px;
  width: 200px;
  margin-left: -100px;
  padding: 5px;
  transition: opacity .3s;
  position: absolute;
  left: 650%;
}

.cmp-tool-tip__tooltip_content:after {
  content: "";
  border: 5px solid #0000;
  border-bottom-color: #555;
  margin-left: -5px;
  position: absolute;
  top: -25%;
  left: 5%;
}

.tableHeader span {
  color: #000;
  text-align: left;
  background-color: #e9f7ea;
  width: 100%;
}

.tableHeader .tableGrid {
  border-collapse: collapse;
  width: 98%;
  overflow-x: auto;
}

.tableHeader .tableGrid th {
  text-align: left;
  background-color: #dadbe5;
  border: none;
  height: 27px;
  padding: 10px;
  font-size: 12px;
  font-weight: normal;
}

.tableHeader .tableGrid .location-header td {
  color: #121212;
  text-align: left;
  background-color: #e9fcf9;
  font-weight: normal;
}

.tableHeader .tableGrid td {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  padding: 12px;
  font-size: 14px;
}

.cmp-all-metrics {
  flex-direction: column;
  display: flex;
}

.cmp-all-metrics_section {
  border: 1px solid #c5ccd480;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  margin-top: 16px;
  display: flex;
}

.cmp-all-metrics_section__foonote {
  padding-top: 10px;
  padding-left: 10px;
  font-size: 12px;
  display: flex;
}

.cmp-all-metrics_section__title {
  background-color: #2fbeb7;
  border-radius: 8px 8px 0 0;
  padding: 14px;
}

.cmp-all-metrics_section__title_name {
  color: #121212;
  font-family: Gotham-Bold;
  font-size: 16px;
}

.cmp-all-metrics_section__tbl .tableGrid {
  border-collapse: separate;
  border-spacing: 0;
  border-top: .5px solid #ccc;
  width: 100%;
  overflow: auto;
}

.cmp-all-metrics_section__tbl .tableGrid th {
  text-align: left;
  background-color: #e1f8f6;
  padding: 14px;
  font-family: Gotham-Medium;
  font-size: 16px;
}

.cmp-all-metrics_section__tbl .tableGrid tr:last-child td:first-child {
  border-radius: 0 0 0 8px;
}

.cmp-all-metrics_section__tbl .tableGrid tr:last-child td:last-child {
  border-radius: 0 0 8px;
}

.cmp-all-metrics_section__tbl .tableGrid td {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  padding: 12px;
  font-size: 14px;
}

.cmp-all-metrics_section__tbl .tableGrid td:first-child {
  width: 30%;
}

@media only screen and (width >= 100px) and (width <= 599px) {
  .cmp-all-metrics_section__tbl .tableGrid th {
    padding: 12px;
    font-size: 14px;
  }

  .cmp-all-metrics_section__tbl .tableGrid td {
    padding: 10px;
    font-size: 12px;
  }

  .cmp-all-metrics_section__tbl .tableGrid td:first-child {
    width: 25%;
  }
}

.cmp-all-metrics_section__tbl .tableGrid .location-header td {
  color: #121212;
  text-align: left;
  background-color: #dadbe5;
  font-family: Gotham-Medium;
}

.cmp-all-metrics__filter-header {
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
  padding: 0 10px;
  display: flex;
}

.cmp-all-metrics__filter-text {
  padding-right: 13px;
  font-family: Gotham-Medium;
  font-weight: 600;
}

.cmp-all-metrics__filter-container {
  align-items: center;
  display: flex;
}

.cmp-all-metrics__filter-main {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  display: flex;
}

.cmp-all-metrics__filter-main .css-1u9des2-indicatorSeparator, .cmp-all-metrics__filter-main .css-894a34-indicatorSeparator {
  display: none;
}

.cmp-all-metrics__filter-main .css-1p3m7a8-multiValue {
  background-color: #e1f8f6;
  font-family: Gotham-Medium;
}

.cmp-all-metrics__filter-main .css-1dimb5e-singleValue {
  background-color: #e1f8f6;
  padding: 1px;
  font-family: Gotham-Medium;
}

.cmp-all-metrics__filter-main span {
  vertical-align: middle;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0 5px;
  padding: 5px 10px;
}

.cmp-all-metrics__filter-main .css-1xc3v61-indicatorContainer:first-child, .cmp-all-metrics__filter-main .css-1hb7zxy-IndicatorsContainer:first-child {
  display: none;
}

.cmp-all-metrics__filter-main div__basic_multi_select {
  width: 16vw;
}

.cmp-all-metrics__filter-main div__basic_multi_select__control {
  min-height: 30px;
}

.cmp-all-metrics__filter-main div__basic_multi_select__control__value-container {
  padding: 0 2px;
}

.cmp-all-metrics__filter-main div__basic_multi_select__control__value-container__multi-value {
  border-radius: 5px;
}

.cmp-all-metrics__filter-main div__basic_multi_select__control__value-container__multi-value__label {
  font-size: 70%;
}

.cmp-all-metrics__filter-main div__basic_multi_select__control__value-container__input-container {
  display: none;
}

.cmp-all-metrics__filter-main div__basic_multi_select__control__indicators__indicator {
  padding: 4px;
}

.cmp-all-metrics__filter-main div__basic_multi_select__control__indicators__separator {
  display: none;
}

.cmp-toggle-switch {
  cursor: pointer;
  background: #0a418b38;
  border-radius: 20px;
  width: 160px;
  height: 40px;
  font-family: Gotham-Medium;
  transition: background .3s;
  position: relative;
}

.cmp-toggle-switch__knob {
  color: #333;
  background: #fff;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  width: 78px;
  height: 30px;
  font-size: 14px;
  font-weight: bold;
  transition: all .3s;
  display: flex;
  position: absolute;
  top: 5px;
}

.cmp-toggle-switch__knob--yearly {
  color: #000;
  background: #c5faf1;
  left: 5px;
}

.cmp-toggle-switch__knob--quarterly {
  color: #000;
  background: #c5faf1;
  left: calc(100% - 85px);
}

.cmp-toggle-switch__label-container {
  pointer-events: none;
  justify-content: space-between;
  align-items: center;
  width: 89%;
  height: 100%;
  padding: 0 10px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.cmp-toggle-switch__label {
  font-size: 14px;
  font-weight: bold;
  transition: color .3s;
}

.cmp-toggle-switch__label--inactive {
  color: #999;
}

.cmp-compare-cards {
  margin: 15px 10px 10px;
  position: sticky;
  top: 10px;
}

@media only screen and (width >= 100px) and (width <= 599px) {
  .cmp-compare-cards {
    flex-direction: column;
    display: flex;
    position: sticky;
  }
}

.cmp-compare-cards_title {
  color: #696969;
  padding-left: 5px;
  font-family: Gotham-Medium;
  font-size: 18px;
}

.cmp-compare-cards__card {
  cursor: pointer;
  background-color: #0a418b38;
  border-radius: 8px;
  margin: 8px 0;
  padding: 20px;
}

@media only screen and (width >= 100px) and (width <= 599px) {
  .cmp-compare-cards__card {
    flex-direction: row;
    display: flex;
  }
}

.cmp-compare-cards__card_body {
  flex-direction: column;
  display: flex;
}

.cmp-compare-cards__card_body--name {
  color: #121212;
  font-family: Gotham-Bold;
  font-size: 24px;
}

.cmp-compare-cards__card_body--assets {
  color: #121212;
  margin-top: 5px;
  font-size: 12px;
}

.cmp-compare-cards__card_body--assets--value {
  font-family: Gotham-Bold;
}

.cmp-compare-cards__card_body--locations {
  font-size: 12px;
}

.cmp-compare-cards__card_body--locations--link {
  cursor: pointer;
}

.cmp-compare-cards__card_body--locations--link:hover {
  text-decoration: underline;
}

.cmp-compare-cards_hr {
  color: #adadad;
  width: 97%;
}

.cmp-compare-cards_lockedPanel, .cmp-compare-cards_lockedPanel2, .cmp-compare-cards_lockedPanel3 {
  opacity: .89;
  text-align: center;
  background-color: #e9e9e9;
  border-radius: 8px;
  place-content: center;
  align-items: center;
  width: 100%;
  font-family: Gotham-Bold;
  font-size: 16px;
  display: flex;
  position: absolute;
}

.cmp-compare-cards_lockedPanel_div, .cmp-compare-cards_lockedPanel2_div, .cmp-compare-cards_lockedPanel3_div {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.cmp-compare-cards_lockedPanel_div_maintitle, .cmp-compare-cards_lockedPanel2_div_maintitle, .cmp-compare-cards_lockedPanel3_div_maintitle {
  flex-direction: column;
  width: 75%;
  display: flex;
}

.cmp-compare-cards_lockedPanel_div_icon, .cmp-compare-cards_lockedPanel2_div_icon, .cmp-compare-cards_lockedPanel3_div_icon {
  width: 25%;
}

.cmp-compare-cards_lockedPanel:hover, .cmp-compare-cards_lockedPanel2:hover, .cmp-compare-cards_lockedPanel3:hover {
  opacity: .89;
  border-radius: 1px solid #353434;
  cursor: pointer;
  color: #fff;
  background-color: #5f5e5e;
  font-family: Gotham-Bold;
}

.cmp-compare-cards_lockedPanel {
  height: 342px;
  top: 26px;
}

.cmp-compare-cards_lockedPanel_div .cmp-compare-cards_lockedPanel_maintitle {
  gap: 30px;
}

.cmp-compare-cards_lockedPanel2 {
  height: 86px;
  top: 415px;
}

.cmp-compare-cards_lockedPanel2_div .cmp-compare-cards_lockedPanel2_maintitle {
  gap: 12px;
}

.cmp-compare-cards_lockedPanel3 {
  height: 85px;
  top: 550px;
}

.cmp-compare-cards_lockedPanel3_div .cmp-compare-cards_lockedPanel3_maintitle {
  gap: 12px;
}

.cmp-portfolio__main {
  flex-direction: row;
  margin: 10px 16px;
  display: flex;
}

.cmp-portfolio__main__allmetrics {
  width: 70%;
  max-width: 70%;
}

.cmp-portfolio__main__allmetrics--compare {
  display: none;
}

.cmp-portfolio__main__Cards {
  width: 30%;
  position: sticky;
}

@media only screen and (width >= 100px) and (width <= 599px) {
  .cmp-portfolio__main .cmp-portfolio {
    overflow-x: auto;
  }

  .cmp-portfolio__main__allmetrics {
    width: 100%;
    max-width: 100%;
  }

  .cmp-portfolio__main__Cards {
    width: 100%;
    display: flex;
  }
}

.cmp-portfolio .tableHeader {
  border: .5px solid #c5ccd480;
  margin-top: 16px;
}

.cmp-portfolio .tableHeader span {
  color: #000;
  background-color: #e9f7ea;
  width: 98.4%;
  padding: 8px;
  font-size: 16px;
  font-weight: bold;
  display: block;
}

.orangeLight {
  background-color: #faf6e4;
}

.purpleLight {
  background-color: #f7ecfd;
}

.width35 {
  width: 35%;
}

.width25 {
  width: 25%;
}

.width10 {
  width: 10%;
}

.width15 {
  width: 15%;
}

.cmp-hero-banner__banner {
  color: #fff;
}

.cmp-hero-banner__banner-title {
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  margin: 0;
  padding: 0 10px;
  font-family: Gotham-Bold;
  font-size: clamp(12px, 12px + 1vw, 34px);
}

.cmp-hero-banner__banner-subtitle {
  text-align: center;
  width: calc(50.9% + 1vw);
  font-family: Gotham;
  font-size: max(4px + 1vw, 16px);
}

.cmp-hero-banner__container--img {
  width: calc(98.9% + 1vw);
}

@media only screen and (width >= 100px) and (width <= 767px) {
  .cmp-hero-banner__container__banner {
    padding: 0 10px;
  }

  .cmp-hero-banner__container--img {
    height: 200px;
  }
}

.cmp-card-layout {
  background-color: #e9fcf9;
  border-radius: 12px;
  width: 47%;
  padding: 10px;
  box-shadow: 0 5px 10px #0000001a;
  margin: 8px !important;
}

@media (width <= 480px) {
  .cmp-card-layout {
    width: 90%;
  }
}

@media (width >= 481px) and (width <= 768px) {
  .cmp-card-layout {
    width: 90%;
  }
}

@media (width >= 769px) and (width <= 900px) {
  .cmp-card-layout {
    width: 90%;
  }
}

@media (width >= 900px) {
  .cmp-card-layout {
    width: 46%;
  }
}

.cmp-card-layout__header {
  padding: 0 14px;
}

.cmp-card-layout__header-table {
  width: 100%;
  margin-bottom: 8px;
  padding-top: 3px;
  padding-left: 1px;
}

.cmp-card-layout__header-tr {
  width: 50%;
}

.cmp-card-layout__header-tr--reitname {
  color: #0a418b;
  font-family: Gotham-Bold;
  font-size: 22px;
  font-weight: 700;
}

.cmp-card-layout__header-tr--location {
  color: #0c1420;
  flex-wrap: wrap;
  width: 95%;
  font-size: 12px;
  display: flex;
}

.cmp-card-layout__header-tr--date {
  text-align: right;
  color: #0c1420;
  font-size: 12px;
}

.cmp-card-layout__hr {
  margin: 0 15px;
}

.cmp-card-layout__body {
  padding: 0 12px;
}

.cmp-card-layout__body-table {
  width: 100%;
  padding: 3px 0 0 1px;
}

.cmp-card-layout__body-table-tbody-topdetails-head {
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  display: flex;
}

.cmp-card-layout__body-table-tbody-topdetails-head--title {
  color: #677486;
  margin: 0 5px 0 0;
  font-family: Gotham-Bold;
  font-size: 14px;
}

.cmp-card-layout__body-table-tbody-topdetails-head--live {
  color: #fff;
  background-color: #267f78;
  padding: 2px;
  font-family: Gotham-Bold;
  font-size: 10px;
}

.cmp-card-layout__body-table-tbody-topdetails-exchange {
  color: #267f78;
  align-items: center;
  width: 50%;
  margin: 10px 0;
  display: flex;
}

.cmp-card-layout__body-table-tbody-topdetails-exchange--bse {
  font-family: Gotham-Bold;
  font-family: 34px;
  color: #0c1420;
}

.cmp-card-layout__body-table-tbody-topdetails-exchange-arrow {
  margin-left: 4px;
  font-family: Gotham-Medium;
  font-size: 12px;
}

.cmp-card-layout__body-table-tbody-topdetails-exchange-arrow--positive {
  color: #5ad05a;
}

.cmp-card-layout__body-table-tbody-topdetails-exchange-arrow--negative {
  color: red;
}

.cmp-card-layout__body-table-tbody-topdetails-exchange-arrow--neutral {
  color: #121212;
}

.cmp-card-layout__body-table-tbody-btmdetails-cell {
  width: 50%;
}

.cmp-card-layout__body-table-tbody-btmdetails-cell--title {
  color: #677486;
  text-transform: uppercase;
  font-family: Gotham-Medium;
  font-size: 14px;
}

.cmp-card-layout__body-table-tbody-btmdetails-cell--value {
  color: #0c1420;
  font-family: Gotham-Bold;
  font-size: calc(12% 1vw);
  margin: 10px 0;
}

.cmp-card-layout__footer {
  padding: 9px 0 4px 10px;
}

.cmp-card-layout__footer-btn {
  color: #fff;
  cursor: pointer;
  background-color: #2fbeb7;
  border: none;
  border-radius: 25px;
  width: 120px;
  height: 30px;
  padding: 4px 16px;
  font-size: 16px;
}

.cmp-card-layout__footer-btn:hover {
  background-color: #049992;
  box-shadow: 10px 10px 20px #0000001a;
}

.cmp-card-panel {
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 6px;
  display: flex;
}

.cmp-search {
  justify-content: center;
  align-items: center;
  display: flex;
}

.cmp-search__container_searchbox {
  display: flex;
}

.cmp-search__container_searchbox-mainfilter {
  width: 170px;
}

@media only screen and (width >= 100px) and (width <= 599px) {
  .cmp-search__container_searchbox-mainfilter {
    width: 125px;
  }
}

@media only screen and (width >= 600px) and (width <= 767px) {
  .cmp-search__container_searchbox-mainfilter {
    width: 140px;
  }
}

@media only screen and (width >= 768px) and (width <= 991px) {
  .cmp-search__container_searchbox-mainfilter {
    width: 160px;
  }
}

.cmp-search__container_searchbox-mainfilter .css-b62m3t-container {
  font-size: 12px;
}

.cmp-search__container_searchbox-mainfilter .css-b62m3t-container .css-1nmdiq5-menu {
  margin: 2px;
  padding: 0;
}

.cmp-search__container_searchbox-mainfilter .css-b62m3t-container .css-13cymwt-control {
  border-color: #e6e6e6;
  border-radius: 4px 0 0 4px;
  height: 34px;
  min-height: 30px;
}

.cmp-search__container_searchbox-mainfilter .css-b62m3t-container .css-13cymwt-control .css-1xc3v61-indicatorContainer svg {
  height: 15px !important;
}

.cmp-search__container_searchbox-mainfilter .css-b62m3t-container .css-13cymwt-control .css-1hb7zxy-IndicatorsContainer .css-1u9des2-indicatorSeparator {
  display: none;
}

.cmp-search__container_searchbox-mainfilter .css-b62m3t-container .css-13cymwt-control .css-1hb7zxy-IndicatorsContainer .css-1u9des2-indicatorSeparator .css-1xc3v61-indicatorContainer svg {
  height: 15px !important;
}

.cmp-search__container_searchbox-mainfilter .css-b62m3t-container .css-t3ipsp-control {
  border-radius: 4px 0 0 4px;
  height: 34px;
  min-height: 30px;
}

.cmp-search__container_searchbox-mainfilter .css-b62m3t-container .css-t3ipsp-control .css-1hb7zxy-IndicatorsContainer .css-1u9des2-indicatorSeparator {
  display: none;
}

.cmp-search__container_searchbox-mainfilter .css-b62m3t-container .css-t3ipsp-control .css-1hb7zxy-IndicatorsContainer .css-1u9des2-indicatorSeparator .css-1xc3v61-indicatorContainer svg {
  height: 15px !important;
}

.cmp-search__container_searchbox-subfilter {
  width: 385px;
  padding-left: 1px;
}

@media only screen and (width >= 100px) and (width <= 599px) {
  .cmp-search__container_searchbox-subfilter {
    width: 230px;
  }
}

@media only screen and (width >= 600px) and (width <= 767px) {
  .cmp-search__container_searchbox-subfilter {
    width: 260px;
  }
}

@media only screen and (width >= 768px) and (width <= 991px) {
  .cmp-search__container_searchbox-subfilter {
    width: 290px;
  }
}

.cmp-search__container_searchbox-subfilter .css-b62m3t-container {
  z-index: 30;
  font-size: 12px;
}

.cmp-search__container_searchbox-subfilter .css-b62m3t-container .css-1nmdiq5-menu {
  margin: 2px;
  padding: 0;
}

.cmp-search__container_searchbox-subfilter .css-13cymwt-control {
  border-color: #e6e6e6;
  border-radius: 0 4px 4px 0;
  height: 34px;
  min-height: 30px;
}

.cmp-search__container_searchbox-subfilter .css-13cymwt-control .css-1xc3v61-indicatorContainer svg {
  height: 15px !important;
}

.cmp-search__container_searchbox-subfilter .css-13cymwt-control .css-1hb7zxy-IndicatorsContainer .css-1u9des2-indicatorSeparator {
  display: none;
}

.cmp-search__container_searchbox-subfilter .css-13cymwt-control .css-1hb7zxy-IndicatorsContainer .css-1u9des2-indicatorSeparator .css-1xc3v61-indicatorContainer svg {
  height: 15px !important;
}

.cmp-search__container_searchbox-subfilter .css-3iigni-container {
  pointer-events: not-allowed;
  font-size: 12px;
}

.cmp-search__container_searchbox-subfilter .css-3iigni-container .css-16xfy0z-control {
  border-radius: 0 4px 4px 0;
  height: 34px;
  min-height: 30px;
}

.cmp-search__container_searchbox-subfilter .css-3iigni-container .css-16xfy0z-control .css-1hb7zxy-IndicatorsContainer .css-894a34-indicatorSeparator {
  display: none;
}

.cmp-search__container_searchbox-subfilter .css-3iigni-container .css-16xfy0z-control .css-1hb7zxy-IndicatorsContainer .css-1xc3v61-indicatorContainer svg {
  height: 15px !important;
}

.cmp-search__container_searchbox-subfilter .css-t3ipsp-control {
  border-radius: 0 4px 4px 0;
  height: 34px;
  min-height: 30px;
}

.cmp-search__container_searchbox-subfilter .css-t3ipsp-control .css-1hb7zxy-IndicatorsContainer .css-1u9des2-indicatorSeparator {
  display: none;
}

.cmp-search__container_searchbox-subfilter .css-t3ipsp-control .css-1hb7zxy-IndicatorsContainer .css-1u9des2-indicatorSeparator .css-1xc3v61-indicatorContainer svg {
  height: 15px !important;
}

.cmp-search-action {
  justify-content: center;
  align-items: center;
  margin: 15px;
  display: flex;
}

.cmp-search-action__container_title {
  justify-content: center;
  display: flex;
}

.cmp-search-action__container_title-text {
  font-family: Gotham-Medium;
  font-size: calc(6px + 1vw);
}

.cmp-search-action__container_control {
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  display: flex;
}

@media only screen and (width >= 10px) and (width <= 991px) {
  .cmp-search-action__container_control {
    flex-direction: column;
    gap: 10px;
  }
}

.cmp-search-action__container_control-btn {
  padding-left: 21px;
  padding-right: 21px;
  display: flex;
}

.cmp-search-action__container_control-btn-compare {
  color: #fff;
  background-color: #10304e;
  border: none;
  border-radius: 25px;
  width: 101px;
  height: 30px;
  margin-right: 10px;
  padding: 4px 16px;
  font-size: 16px;
}

.cmp-search-action__container_control-btn-valuation {
  color: #fff;
  background-color: #10304e;
  border: none;
  border-radius: 25px;
  width: 101px;
  height: 30px;
  padding: 4px 16px;
  font-size: 16px;
}

.cmp-search-action__container_control-btn-compare:hover, .cmp-search-action__container_control-btn-valuation:hover {
  background-color: #0a418b;
  box-shadow: 10px 10px 20px #0000001a;
}

.contact-form-container {
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
  display: flex;
}

.image-section {
  background-image: url("map-banner.2b0b10ec.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.form-title {
  justify-content: center;
  align-items: center;
  display: flex;
}

.contact-form {
  color: #000;
  background: #fff;
  border: 2px solid #000;
  border-radius: 8px;
  width: 25vw;
  padding: 30px;
}

.contact-form h2 {
  color: #000;
  margin-bottom: 20px;
}

.contact-form .form-group {
  margin-bottom: 15px;
  position: relative;
}

.captcha {
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  display: flex;
}

.contact-form label {
  color: #000;
  pointer-events: none;
  font-size: 1em;
  transition: all .3s;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.contact-form input, .contact-form textarea {
  color: #000;
  background-color: #0000;
  border: none;
  border-bottom: 2px solid #ddd;
  outline: none;
  width: 100%;
  padding: 10px 0;
  font-size: 1em;
}

.contact-form input:focus, .contact-form textarea:focus {
  border-bottom: 2px solid #189ab4;
}

.contact-form input:focus ~ label, .contact-form input:not(:placeholder-shown) ~ label, .contact-form textarea:focus ~ label, .contact-form textarea:not(:placeholder-shown) ~ label {
  color: #189ab4;
  font-size: .8em;
  top: -10px;
}

.contact-form textarea {
  resize: none;
  min-height: 40px;
}

.contact-form .message-limit {
  text-align: right;
  color: #ccc;
  font-size: .8em;
}

.contact-form button {
  color: #fff;
  cursor: pointer;
  background-color: #10304e;
  border: none;
  border-radius: 5px;
  width: 100%;
  padding: 12px;
  font-size: 1em;
  transition: background-color .3s;
}

.contact-form button:hover {
  background-color: #1c7c91;
}

.left-section {
  color: #fff;
  background-color: #1b314c;
  min-width: 30vw;
  min-height: 30vh;
}

.content {
  margin-left: 30px;
}

.first-header {
  padding: 20px 0;
}

.mail-contact {
  align-items: center;
  margin-top: 3px;
  padding: 5px;
  display: flex;
}

.mail-contact .contact-icon {
  padding-left: 30px;
}

.mail-contact a {
  color: #fff;
  padding: 5px;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
}

.contact-icons {
  padding-left: 50px;
}

.short-text {
  font-size: 12px;
}

.cmp-dashboard__footnote {
  background-color: #10304e;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 10px;
  display: flex;
}

@media only screen and (width >= 100px) and (width <= 991px) {
  .cmp-dashboard__footnote {
    flex-direction: column;
    justify-content: normal;
  }
}

@media only screen and (width >= 992px) and (width <= 1199px) {
  .cmp-dashboard__footnote {
    justify-content: center;
  }
}

.cmp-dashboard__footnote-vr {
  background-color: #e9fcf9;
  width: .5px;
  height: 200px;
  position: relative;
  top: 54px;
}

@media only screen and (width >= 100px) and (width <= 991px) {
  .cmp-dashboard__footnote-vr {
    width: 87%;
    height: .5px;
    position: relative;
    top: 10px;
    left: 24px;
  }
}

.cmp-dashboard__footnote-tab {
  color: #fff;
  text-align: justify;
  background-color: #10304e;
  flex-direction: column;
  width: 33%;
  padding: 18px 25px;
  display: flex;
}

@media only screen and (width >= 100px) and (width <= 991px) {
  .cmp-dashboard__footnote-tab {
    width: 90%;
    padding: 10px 18px;
  }
}

.cmp-dashboard__footnote-tab--title {
  color: #2fbeb7;
  margin: 10px 0 15px;
  font-family: Gotham-Bold;
  font-size: 20px;
}

@media only screen and (width >= 100px) and (width <= 991px) {
  .cmp-dashboard__footnote-tab--title {
    font-size: 16px;
  }
}

@media only screen and (width >= 992px) and (width <= 1199px) {
  .cmp-dashboard__footnote-tab--title {
    font-size: 18px;
  }
}

.cmp-dashboard__footnote-tab--subtitle {
  color: #fdfdfe;
  font-size: 16px;
  font-weight: normal;
}

@media only screen and (width >= 100px) and (width <= 991px) {
  .cmp-dashboard__footnote-tab--subtitle {
    font-size: 12px;
  }
}

@media only screen and (width >= 992px) and (width <= 1199px) {
  .cmp-dashboard__footnote-tab--subtitle {
    font-size: 14px;
  }
}

.cmp-dashboard__footer-button {
  color: #fff;
  cursor: pointer;
  background-color: #10304e;
  border: none;
  border-radius: 50px;
  padding: 15px 20px;
  font-size: 16px;
  transition: all .3s;
  position: fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: 0 8px 15px #0003;
}

.cmp-dashboard__footer-button:hover {
  background-color: #2c376b;
  transform: translateY(-5px);
  box-shadow: 0 12px 20px #0000004d;
}

.cmp-financial-compare-table .css-1p3m7a8-multiValue {
  background-color: #e1f8f6;
}

.cmp-financial-compare-table .css-1dimb5e-singleValue {
  background-color: #e1f8f6;
  border-radius: 6px;
  padding: 4px 6px;
  font-size: 14px;
}

.cmp-financial-compare-table .titleFilter {
  justify-content: center;
  align-items: center;
  display: flex;
}

.cmp-financial-compare-table .titleFilter h4 {
  width: 40%;
  margin: 0;
  font-size: 20px;
  font-weight: 400;
}

.cmp-financial-compare-table .titleFilter .filterMain {
  z-index: 10;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  display: flex;
}

.cmp-financial-compare-table .titleFilter .filterMain span {
  vertical-align: middle;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0 5px;
  padding: 5px 10px;
}

.cmp-financial-compare-table .titleFilter .filterMain div .basic-multi-select {
  font-family: Gotham;
}

.cmp-financial-compare-table .titleFilter .filterMain div .basic-multi-select .select__control {
  min-height: 30px;
}

.cmp-financial-compare-table .titleFilter .filterMain div .basic-multi-select .select__control .select__value-container {
  padding: 0 2px;
}

.cmp-financial-compare-table .titleFilter .filterMain div .basic-multi-select .select__control .select__value-container .select__multi-value {
  border-radius: 5px;
}

.cmp-financial-compare-table .titleFilter .filterMain div .basic-multi-select .select__control .select__value-container .select__multi-value .select__multi-value__label {
  font-size: 70%;
}

.cmp-financial-compare-table .titleFilter .filterMain div .basic-multi-select .select__control .select__indicators .select__indicator {
  padding: 4px;
}

.cmp-financial-compare-table .titleFilter .filterMain div .basic-multi-select .select__control .select__indicators span.select__indicator-separator {
  display: none;
}

.cmp-financial-compare-table .tableHeader {
  margin-top: 16px;
}

.cmp-financial-compare-table .tableHeader span {
  color: #000;
  background-color: #e9f7ea;
  width: 98.3%;
  padding: 8px;
  font-size: 16px;
  font-weight: bold;
  display: block;
}

.cmp-financial-compare-table .tableHeader .tableGrid {
  border-collapse: collapse;
  border: 1px solid #ccc;
  width: 100%;
  overflow: auto;
}

.cmp-financial-compare-table .tableHeader .tableGrid th {
  text-align: left;
  background-color: #dadbe5;
  border: none;
  height: 27px;
  padding: 10px;
  font-size: 12px;
  font-weight: normal;
}

.cmp-financial-compare-table .tableHeader .tableGrid .location-header td {
  color: #121212;
  text-align: left;
  background-color: #e9fcf9;
  font-weight: normal;
}

.cmp-financial-compare-table .tableHeader .tableGrid td {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  padding: 12px;
  font-size: 14px;
}

.cmp-financial-compare-table .tableHeader .tableGrid td:first-child {
  width: 30%;
}

.cmp-financial-compare-table .tableHeader .tableGrid .subMaintitle {
  background-color: #2fbeb7;
  font-family: Gotham-Medium;
  font-size: 14px;
}

.cmp-financial-compare-table .tableHeader .tableGrid .subtitle {
  background-color: #e9fcf9;
  font-size: 14px;
}

.cmp-financial-compare-table .tableHeader .tableGrid .reitsName th {
  text-align: center;
  height: 80px;
  font-family: Gotham-Bold;
  font-size: 14px;
}

.cmp-financial-compare-table .tableHeader .tableGrid .reitsName th:first-child {
  background-color: #0000;
  width: 30%;
}

.cmp-financial-compare-table .tableHeader .tableGrid .reitsName th:nth-child(2) {
  background-color: #e9eff7;
}

.cmp-financial-compare-table .tableHeader .tableGrid .reitsName th:nth-child(3) {
  background-color: #ebf7f6;
}

.cmp-financial-compare-table .tableHeader .tableGrid .reitsName th:nth-child(4) {
  background-color: #e8eaed;
}

.cmp-financial-compare-table .tableHeader .tableGrid .reitsName th:last-child {
  background-color: #fff;
  width: 10%;
}

.cmp-financial-compare-table .tableHeader .tableGrid .reitsName .addReits div {
  justify-content: center;
  align-items: center;
  display: flex;
}

.cmp-financial-compare-table .tableHeader .tableGrid .reitsName .close {
  background-clip: text;
  width: 10%;
  padding: 1px;
  position: relative;
  top: -25px;
  left: 89%;
}

.cmp-financial-compare-table .tableHeader .tableGrid td.reitsLastCol {
  border-left: 1px solid #dadbe5;
  border-right: 1px solid #dadbe5;
}

.cmp-adjacent-table {
  border-radius: 35px;
  justify-content: center;
  margin-bottom: 10px;
  display: flex;
}

.cmp-adjacent-table__table {
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #ccc;
  border-radius: 0 0 8px 8px;
  width: 100%;
  overflow: auto;
}

.cmp-adjacent-table__table tr:last-child td:first-child {
  border-radius: 0 0 0 8px;
}

.cmp-adjacent-table__table tr:last-child td:last-child {
  border-radius: 0 0 8px;
}

.cmp-adjacent-table__table--th {
  text-align: left;
  z-index: 9;
  background-color: #dadbe5;
  border: none;
  height: 27px;
  padding: 10px;
  font-family: Gotham-Medium;
  font-size: 16px;
  position: sticky;
  top: 0;
}

.cmp-adjacent-table__table--td {
  white-space: pre-wrap;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  padding: 15px;
  font-size: 14px;
}

.cmp-adjacent-table__table--td:first-child {
  width: 25%;
}

.cmp-adjacent-table__table--td:first-child:after {
  width: 15%;
}

@media only screen and (width <= 635px) {
  .cmp-adjacent-table {
    flex-direction: column;
  }

  .cmp-adjacent-table__table {
    width: 100%;
    display: block;
    overflow-x: auto;
  }

  .cmp-adjacent-table__table tr {
    display: table-row;
  }

  .cmp-adjacent-table--th, .cmp-adjacent-table--td {
    padding: 10px;
    font-size: 14px;
  }
}

.graph_main {
  width: 100%;
  display: flex;
}

.graph_main div.graph_image {
  width: 70%;
  height: 350px;
}

.graph_main div.graph_image img {
  width: 97%;
  height: 95%;
}

.graph_main div.graph_content {
  flex-direction: column;
  width: 30%;
  display: flex;
}

.graph_main div.graph_content span {
  margin: 10px;
  font-weight: 600;
}

.graph_main div.graph_content button {
  text-align: left;
  background-color: #e9fcf9;
  border: none;
  border-radius: 6px;
  width: 100%;
  margin: 5px;
  padding: 10px 16px;
}

.section_header {
  background-color: #e9f7ea;
  width: 100%;
  padding: 15px 10px;
  font-weight: 600;
}

.cmp-compare-adjacent {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.cmp-compare-adjacent_map {
  width: 98%;
}

@media only screen and (width <= 767px) {
  .cmp-compare-adjacent_map {
    flex-direction: column;
  }
}

.cmp-compare-adjacent_map .graph_main {
  flex-direction: row;
  display: flex;
}

@media only screen and (width <= 767px) {
  .cmp-compare-adjacent_map .graph_main {
    flex-direction: column;
  }
}

.cmp-compare-adjacent_map .graph_main .graph_image {
  width: 70%;
}

@media only screen and (width <= 767px) {
  .cmp-compare-adjacent_map .graph_main .graph_image {
    width: 100%;
  }
}

.cmp-compare-adjacent_map .graph_main .graph_content {
  flex-direction: column;
  align-items: center;
  width: 30%;
  margin-top: 10px;
  display: flex;
}

@media only screen and (width <= 767px) {
  .cmp-compare-adjacent_map .graph_main .graph_content {
    width: 100%;
    margin-top: 0;
  }
}

.cmp-compare-adjacent_search {
  height: 30px;
  margin-bottom: 10px;
  padding: 12px;
  display: flex;
}

.cmp-compare-adjacent_main {
  width: 98%;
}

.cmp-compare-adjacent_main_header {
  width: 100%;
}

.cmp-compare-adjacent_main_header-title {
  background-color: #3abeb7;
  border-radius: 8px 8px 0 0;
  padding: 15px 10px;
  font-family: Gotham-Bold;
}

.cmp-compare-adjacent_main_body {
  width: 100%;
}

.dynamic-dropdown-table {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
  font-size: 16px;
}

.dynamic-dropdown-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.dynamic-dropdown-table tbody tr:nth-of-type(2n) {
  background-color: #f9f9f9;
}

.dynamic-dropdown-table tbody tr:hover {
  background-color: #f1f1f1;
}

.add-row-btn, .remove-row-btn {
  cursor: pointer;
  text-align: center;
  justify-content: center;
  align-items: center;
  line-height: 28px;
  display: flex;
  background-color: #fff !important;
  border: 1px solid #333 !important;
  border-radius: 50% !important;
  width: 35px !important;
  height: 35px !important;
}

.add-row-btn:hover, .remove-row-btn:hover {
  background-color: #e7e7e7;
}

.cmp-footer {
  text-align: left;
  color: #fff;
  box-sizing: border-box;
  background-color: #0c1420;
  justify-content: space-between;
  margin-top: .3px;
  padding: .625rem 1rem;
  font-family: Gotham-Medium;
  font-size: 12px;
  display: flex;
  bottom: 0;
  left: 0;
}

.cmp-footer__discailmer {
  cursor: pointer;
  text-decoration: underline;
}

@media (width <= 767px) {
  .cmp-footer {
    flex-direction: column;
  }

  .cmp-footer__discailmer {
    margin-bottom: 10px;
  }
}

.close-btn {
  color: #fff;
  cursor: pointer;
  background-color: #10304e;
  border: none;
  border-radius: 25px;
  width: 100px;
  height: 35px;
  padding: 10px;
  font-family: Gotham-Medium;
  font-size: 12px;
}

.close-btn:hover {
  background-color: #0a418b;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: #ffffffb3;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-offset: 16px;
  --toastify-toast-top: max(var(--toastify-toast-offset), env(safe-area-inset-top));
  --toastify-toast-right: max(var(--toastify-toast-offset), env(safe-area-inset-right));
  --toastify-toast-left: max(var(--toastify-toast-offset), env(safe-area-inset-left));
  --toastify-toast-bottom: max(var(--toastify-toast-offset), env(safe-area-inset-bottom));
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-toast-bd-radius: 6px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
  --toastify-color-progress-bgo: .2;
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
}

.Toastify__toast-container--top-left {
  top: var(--toastify-toast-top);
  left: var(--toastify-toast-left);
}

.Toastify__toast-container--top-center {
  top: var(--toastify-toast-top);
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: var(--toastify-toast-top);
  right: var(--toastify-toast-right);
}

.Toastify__toast-container--bottom-left {
  bottom: var(--toastify-toast-bottom);
  left: var(--toastify-toast-left);
}

.Toastify__toast-container--bottom-center {
  bottom: var(--toastify-toast-bottom);
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: var(--toastify-toast-bottom);
  right: var(--toastify-toast-right);
}

@media only screen and (width <= 480px) {
  .Toastify__toast-container {
    width: 100vw;
    left: env(safe-area-inset-left);
    margin: 0;
    padding: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: env(safe-area-inset-top);
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: env(safe-area-inset-bottom);
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: env(safe-area-inset-right);
    left: initial;
  }
}

.Toastify__toast {
  --y: 0;
  -ms-touch-action: none;
  touch-action: none;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  border-radius: var(--toastify-toast-bd-radius);
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: default;
  z-index: 0;
  direction: ltr;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 12px #0000001a;
}

.Toastify__toast--stacked {
  width: 100%;
  transform: translate3d(0, var(--y), 0) scale(var(--s));
  transition: transform .3s;
  position: absolute;
}

.Toastify__toast--stacked[data-collapsed] .Toastify__toast-body, .Toastify__toast--stacked[data-collapsed] .Toastify__close-button {
  transition: opacity .1s;
}

.Toastify__toast--stacked[data-collapsed="false"] {
  overflow: visible;
}

.Toastify__toast--stacked[data-collapsed="true"]:not(:last-child) > * {
  opacity: 0;
}

.Toastify__toast--stacked:after {
  content: "";
  height: calc(var(--g) * 1px);
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
}

.Toastify__toast--stacked[data-pos="top"] {
  top: 0;
}

.Toastify__toast--stacked[data-pos="bot"] {
  bottom: 0;
}

.Toastify__toast--stacked[data-pos="bot"].Toastify__toast--stacked:before {
  transform-origin: top;
}

.Toastify__toast--stacked[data-pos="top"].Toastify__toast--stacked:before {
  transform-origin: bottom;
}

.Toastify__toast--stacked:before {
  content: "";
  z-index: -1;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scaleY(3);
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast--close-on-click {
  cursor: pointer;
}

.Toastify__toast-body {
  flex: auto;
  align-items: center;
  margin: auto 0;
  padding: 6px;
  display: flex;
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  flex-shrink: 0;
  width: 20px;
  margin-inline-end: 10px;
  display: flex;
}

.Toastify--animate {
  animation-duration: .5s;
  animation-fill-mode: both;
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@media only screen and (width <= 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--light, .Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  z-index: 1;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  width: 14px;
  height: 16px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  width: 100%;
  height: 100%;
  z-index: var(--toastify-z-index);
  opacity: .7;
  transform-origin: 0;
  border-bottom-left-radius: var(--toastify-toast-bd-radius);
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: linear forwards Toastify__trackProgress;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
  border-bottom-left-radius: initial;
  border-bottom-right-radius: var(--toastify-toast-bd-radius);
}

.Toastify__progress-bar--wrp {
  border-bottom-left-radius: var(--toastify-toast-bd-radius);
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--wrp[data-hidden="true"] {
  opacity: 0;
}

.Toastify__progress-bar--bg {
  opacity: var(--toastify-color-progress-bgo);
  width: 100%;
  height: 100%;
}

.Toastify__spinner {
  box-sizing: border-box;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  animation: .65s linear infinite Toastify__spin;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, var(--y), 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, var(--y), 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, var(--y), 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, var(--y), 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, calc(var(--y)  - 10px), 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, calc(var(--y)  + 20px), 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, calc(var(--y)  - 10px), 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, calc(var(--y)  + 20px), 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: translate3d(0, var(--y), 0) scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: translate3d(0, var(--y), 0) perspective(400px);
  }

  30% {
    transform: translate3d(0, var(--y), 0) perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }

  to {
    transform: translate3d(0, var(--y), 0) perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, var(--y), 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, var(--y), 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, var(--y), 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, var(--y), 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, var(--y), 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, var(--y), 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, var(--y), 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, var(--y), 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, var(--y), 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, var(--y), 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
  animation-duration: .3s;
  animation-timing-function: ease-in;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
  animation-duration: .3s;
  animation-timing-function: ease-in;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
  animation-duration: .3s;
  animation-timing-function: ease-in;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
  animation-duration: .3s;
  animation-timing-function: ease-in;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.cmp-evaluation {
  font-weight: normal;
}

.cmp-evaluation__search {
  justify-content: flex-start;
  padding-left: 1rem;
  display: flex;
}

.cmp-evaluation__valuation {
  padding: 1rem;
}

.cmp-evaluation-table__container {
  background-color: #fff;
  border: .5px solid #c5ccd4;
  border-radius: 8px;
  padding: 1rem;
  font-weight: normal;
}

.cmp-evaluation-table__container_selector {
  width: 50%;
  margin-bottom: 16px;
}

.cmp-evaluation-table__container_selector-label {
  margin-right: 10px;
  font-family: Gotham-Bold;
  font-size: 16px;
}

.cmp-evaluation-table__container_selector-dropdown {
  border-radius: 4px;
  padding: 5px;
  font-size: 12px;
}

.cmp-evaluation-table__container_static-table__tbl {
  border-collapse: collapse;
  box-sizing: border-box;
  table-layout: fixed;
  border: .5px solid #c5ccd4;
  border-radius: 4px;
  width: 100%;
}

.cmp-evaluation-table__container_static-table__tbl tbody > tr:first-child {
  background-color: #e1f8f6;
}

.cmp-evaluation-table__container_static-table__tbl tr {
  box-sizing: border-box;
  border-bottom: .5px solid #c5ccd4;
  width: 50%;
  padding: 10px;
}

.cmp-evaluation-table__container_static-table__tbl td {
  padding: 10px;
  font-size: 12px;
}

.cmp-evaluation-table__container_static-table__tbl td:first-child {
  width: 35%;
}

.cmp-evaluation-table__container_static-table__tbl td:last-child {
  font-family: Gotham-Medium;
}

.cmp-evaluation-table__discout-header {
  box-sizing: border-box;
  background-color: #2fbeb7;
  border-radius: 8px;
  margin: 20px 0;
  padding: 20px;
  font-size: 1rem;
}

.cmp-evaluation-table__discout-header-txt {
  font-family: Gotham-Bold;
  font-size: 16px;
  font-weight: 600;
}

.cmp-evaluation-table__boxes {
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

@media only screen and (width >= 100px) and (width <= 599px) {
  .cmp-evaluation-table__boxes {
    flex-flow: column wrap;
    align-items: center;
    gap: 10px;
    display: flex;
  }

  .cmp-evaluation-table__boxes-modal {
    width: 85%;
    height: 200px;
  }
}

.cmp-evaluation-table__boxes-modal {
  border: 1px solid #c5ccd4;
  border-radius: 8px;
  width: 30%;
  height: 161px;
  padding: 12px;
}

@media only screen and (width >= 100px) and (width <= 599px) {
  .cmp-evaluation-table__boxes-modal {
    width: 85%;
  }
}

.cmp-evaluation-table__boxes-modal-header {
  background-color: #e9fcf9;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
}

.cmp-evaluation-table__boxes-modal-header-txt {
  font-family: Gotham-Bold;
  font-size: 16px;
  font-weight: 600;
}

.cmp-evaluation-table__boxes-modal-body {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 14px 16px;
  font-family: Gotham-Medium;
  display: flex;
}

.cmp-evaluation-table__boxes-modal-body-txt {
  font-size: 16px;
  font-weight: 600;
}

.cmp-evaluation-table__boxes-modal-body-value {
  flex-direction: row;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
}

.cmp-evaluation-table__boxes-modal-body-value img {
  cursor: pointer;
  height: 14px;
}

.cmp-evaluation-table__boxes-modal-body-value img:hover {
  text-decoration: underline;
}

.cmp-evaluation-table__disclaimer {
  margin-top: 12px;
  font-size: 14px;
  font-weight: 500;
}

.cmp-evaluation-table__disclaimer p {
  margin: 2px;
}

.cmp-evaluation-table__disclaimer p b {
  font-family: Gotham-Medium;
}

.cmp-cash-flow {
  width: 98%;
  overflow-x: auto;
}

.cmp-cash-flow__navbar {
  border-bottom: 1px solid;
  flex-direction: row;
  width: 100%;
  height: 42px;
  display: flex;
}

.cmp-cash-flow__navbar-btn {
  cursor: pointer;
  background-color: #fff;
  border: none;
  outline: none;
  padding: 10px 16px;
  font-family: Gotham-Medium;
  font-size: 16px;
}

.cmp-cash-flow__navbar-btn:hover {
  background-color: #e9f7ea;
  border-bottom: 3px solid #3338ae;
}

.cmp-cash-flow__navbar-btn--active {
  cursor: pointer;
  color: #2fbeb7;
  background-color: #fff;
  border: none;
  border-bottom: 3px solid #3338ae;
  outline: none;
  padding: 10px 16px;
  font-family: Gotham-Bold;
  font-size: 16px;
}

@media (width <= 585px) {
  .cmp-cash-flow__navbar-btn--active {
    line-height: 15px;
  }
}

.cmp-cash-flow__body {
  background-color: #fff;
  border: .5px solid #c5ccd4;
  border-radius: 8px;
  margin-top: 10px;
  padding: 10px;
  overflow-x: auto;
}

.cmp-cash-flow__body::-webkit-scrollbar {
  width: .5em;
  height: .5em;
}

.cmp-cash-flow__body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #0000004d;
}

.cmp-cash-flow__body::-webkit-scrollbar-thumb {
  background-color: #1c304b;
  border-radius: 50px;
  outline: 1px solid #1c304b;
}

.cmp-cash-flow__body_table {
  text-align: right;
  border-spacing: 0;
  border-collapse: separate;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 100;
}

.cmp-cash-flow__body_table-thead {
  background-color: #2fbeb7;
  border-radius: 8px 8px 0 0;
}

.cmp-cash-flow__body_table-thead th {
  padding: 10px;
}

.cmp-cash-flow__body_table-thead th:first-child {
  text-align: left;
  border-radius: 8px 0 0;
  min-width: 140px;
}

.cmp-cash-flow__body_table-thead th:last-child {
  border-radius: 0 8px 0 0;
  min-width: 100px;
}

.cmp-cash-flow__body_table-tbody tr {
  height: 45px;
}

.cmp-cash-flow__body_table-tbody tr td {
  border-bottom: .5px solid #c5ccd4;
  padding: 5px;
}

.cmp-cash-flow__body_table-tbody tr td:first-child {
  text-align: left;
}

.cmp-cash-flow__body_table-tbody-label {
  width: 350px;
}

.cmp-cash-flow__body_table-tbody tr:last-child td:first-child {
  border-radius: 0 0 0 8px;
}

.cmp-cash-flow__body_table-tbody tr:last-child td:last-child {
  border-radius: 0 0 8px;
}

.cmp-cash-flow__control {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

.cmp-cash-flow__control--cancel {
  background-color: #fff;
  border: .5px solid #3338ae;
  border-radius: 3px;
  margin: 10px;
  padding: 8px;
  font-size: 14px;
}

.cmp-cash-flow__control--cancel:hover {
  color: #fff;
  background-color: #0a418b;
  font-weight: 600;
}

.cmp-cash-flow__control--continue {
  color: #fff;
  background-color: #10304e;
  border: .5px solid #3338ae;
  border-radius: 3px;
  margin: 10px;
  padding: 8px;
  font-size: 14px;
  font-weight: 600;
}

.cmp-cash-flow__control--continue:hover {
  color: #fff;
  background-color: #0a418b;
}

.error input {
  border: 1px solid red;
}

.error-message {
  color: red;
  background-color: #fff;
  border: 1px solid red;
  margin-top: 4px;
  padding: 4px;
  font-size: .8em;
  display: none;
  position: absolute;
}

.error:hover .error-message {
  display: block;
}

input {
  box-sizing: border-box;
  border: 1px solid #2fbeb7;
  border-radius: 4px;
  width: 76px;
  margin: 4px;
  padding: 6px;
  font-size: 12px;
}

label {
  margin-right: 10px;
}

.cashflow__footer {
  border: .5px solid #c5ccd4;
  border-radius: 4px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  padding: 25px;
  display: flex;
}

.cmp-wacc-flow {
  width: 98%;
  overflow-x: auto;
}

.cmp-wacc-flow__navbar {
  border-bottom: 1px solid;
  flex-direction: row;
  width: 100%;
  height: 42px;
  display: flex;
}

.cmp-wacc-flow__navbar-btn {
  cursor: pointer;
  background-color: #fff;
  border: none;
  outline: none;
  padding: 10px 16px;
  font-family: Gotham-Medium;
  font-size: 16px;
}

.cmp-wacc-flow__navbar-btn:hover {
  background-color: #e9f7ea;
  border-bottom: 3px solid #3338ae;
}

.cmp-wacc-flow__navbar-btn--active {
  cursor: pointer;
  color: #2fbeb7;
  background-color: #fff;
  border: none;
  border-bottom: 3px solid #3338ae;
  outline: none;
  padding: 10px 16px;
  font-family: Gotham-Bold;
  font-size: 16px;
}

.cmp-wacc-flow__body {
  width: 100%;
  margin-top: 10px;
  font-size: 12px;
}

.cmp-wacc-flow__body-panel {
  flex-direction: column;
  display: flex;
}

.cmp-wacc-flow__body-panel--model {
  flex-direction: row;
  gap: 10px;
  width: 100%;
  display: flex;
}

.cmp-wacc-flow__body-panel--model--risk, .cmp-wacc-flow__body-panel--model--cost {
  border: .5px solid #ccc;
  border-radius: 8px;
  width: 50%;
  padding: 10px;
}

.cmp-wacc-flow__body-panel--rate {
  border: .5px solid #ccc;
  border-radius: 8px;
  margin-top: 10px;
  padding: 10px;
}

.cmp-wacc-flow__body-panel--rate--txt {
  color: #fff;
  background-color: #10304e;
  border-radius: 8px;
  justify-content: center;
  padding: 16px;
  font-family: Gotham-Bold;
  display: flex;
}

.cmp-wacc-flow__body-panel--rate--value {
  text-align: center;
  color: #1a73e8;
  margin-top: 16px;
  font-size: 24px;
  font-weight: bold;
}

.cmp-wacc-flow__control {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

.cmp-wacc-flow__control--cancel {
  background-color: #fff;
  border: .5px solid #3338ae;
  border-radius: 3px;
  margin: 10px;
  padding: 8px;
  font-size: 14px;
}

.cmp-wacc-flow__control--cancel:hover {
  color: #fff;
  background-color: #0a418b;
  font-weight: 600;
}

.cmp-wacc-flow__control--back {
  background-color: #fff;
  border: .5px solid #3338ae;
  border-radius: 3px;
  margin: 10px;
  padding: 8px;
  font-size: 14px;
}

.cmp-wacc-flow__control--back:hover {
  color: #fff;
  background-color: #0a418b;
  font-weight: 600;
}

.cmp-wacc-flow__control--calculate {
  color: #fff;
  background-color: #10304e;
  border: .5px solid #3338ae;
  border-radius: 3px;
  margin: 10px;
  padding: 8px;
  font-size: 14px;
  font-weight: 600;
}

.cmp-wacc-flow__control--calculate:hover {
  color: #fff;
  background-color: #0a418b;
}

.dropdown-section {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.dropdown-section label {
  font-family: Gotham-Bold;
  display: block;
}

.risk-premium-value {
  text-align: center;
  color: #1a73e8;
  margin-top: 16px;
  font-size: 24px;
  font-weight: bold;
}

.add-risk-premium {
  color: #000;
  cursor: pointer;
  background-color: #e9fcf9;
  border: none;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 12px;
  font-family: Gotham-Bold;
  display: flex;
}

.risk-premium-header {
  background-color: #dadbe5;
  border-radius: 8px 8px 0 0;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  padding: 12px;
  display: flex;
}

.wacc-container {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
}

.range-slider-container {
  width: 100%;
  margin-bottom: 8px;
}

.range-slider-container label {
  background-color: #e9fcf9;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  width: 94%;
  padding: 10px;
  font-family: Gotham-Bold;
  display: flex;
}

.range-slider-container input[type="range"] {
  width: 100%;
  margin-bottom: 8px;
  padding: 0;
}

.range-slider-input {
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  padding: 8px;
}

.wacc-display, .discount-rate-display {
  text-align: center;
  color: #000;
  margin-top: 16px;
  font-weight: bold;
}

.wacc-display-head {
  color: #000;
  cursor: pointer;
  background-color: #e9fcf9;
  border: none;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 12px;
  display: flex;
}

.wacc-display--v {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.wacc-display--v-value {
  text-align: center;
  color: #1a73e8;
  margin-top: 16px;
  font-size: 24px;
  font-weight: bold;
}

.cmp-breadcrumbs_nav {
  text-transform: uppercase;
  border-bottom: .5px solid #dadbe5;
  margin-bottom: 8px;
  padding: 0 0 8px;
  font-family: Gotham-Medium;
}

.cmp-breadcrumbs_nav-span-links {
  color: #696969;
  padding: 10px;
  font-size: 12px;
  text-decoration: none;
}

.cmp-breadcrumbs_nav-span-img {
  width: 8px;
  height: 10px;
}

@media (width <= 480px) {
  .cmp-app .example1 {
    width: 100%;
    min-width: 320px;
    max-width: 480px;
    margin: 0 auto;
  }
}

@media (width >= 481px) and (width <= 768px) {
  .cmp-app .example1 {
    width: 100%;
    max-width: 725px;
    margin: 0 auto;
  }
}

@media (width >= 769px) and (width <= 1024px) {
  .cmp-app .example1 {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
  }
}

@media (width >= 1025px) {
  .cmp-app .example1 {
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
  }
}

.cmp-pricepage__container {
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.cmp-pricepage__column {
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 0;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  max-width: calc(25% - 16px);
  margin: 8px 0;
  padding: 16px;
  display: flex;
}

.cmp-pricepage__column__header, .cmp-pricepage__column__body, .cmp-pricepage__column__footer {
  margin-bottom: 8px;
}

.cmp-pricepage__column__header {
  min-height: 100px;
}

.cmp-pricepage__column__header-title {
  font-size: 25px;
  font-weight: bold;
}

.cmp-pricepage__column__header-price {
  margin-top: 16px;
}

.cmp-pricepage__column__header-price .price-amount {
  font-size: 35px;
  font-weight: bold;
  display: block;
}

.cmp-pricepage__column__header-price .price-period {
  font-size: 14px;
  display: block;
}

.cmp-pricepage__column__body {
  border-top: 1px solid #ccc;
  flex-grow: 1;
  margin-top: 16px;
  padding-top: 8px;
}

.cmp-pricepage__column__body-list {
  text-align: left;
  list-style-type: none;
}

.cmp-pricepage__column__body-list-img {
  width: 8px;
  height: 10px;
  margin-right: 8px;
}

.cmp-pricepage__column__body-list li {
  position: relative;
}

.cmp-pricepage__column__footer-button {
  color: #fff;
  cursor: pointer;
  background-color: #10304e;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 14px;
  transition: background-color .3s;
}

.cmp-pricepage__column__footer-button:hover {
  background-color: #0a418b;
  box-shadow: 10px 10px 20px #0000001a;
}

.container {
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  max-width: 500px;
  margin: 40px auto 0;
  padding: 20px;
}

.pseudo-div {
  height: 200px;
}

header {
  margin-bottom: 20px;
}

.form {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

.input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
}

.button {
  color: #fff;
  cursor: pointer;
  background-color: #10304e;
  border: none;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
}

.cmp-contact-button {
  color: #fff;
  cursor: pointer;
  z-index: 1000;
  background-color: #10304e;
  border: none;
  border-radius: 50px;
  padding: 15px 20px;
  font-size: 16px;
  transition: all .3s;
  position: fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: 0 8px 15px #0003;
}

.cmp-contact-button:hover {
  background-color: #2c376b;
  transform: translateY(-3px);
  box-shadow: 0 12px 20px #0000004d;
}

@media (width <= 767px) {
  .cmp-contact-button {
    writing-mode: vertical-rl;
    border-radius: 0;
    padding: 5px 3px;
    font-size: 14px;
    bottom: 50%;
    right: -2px;
    transform: rotate(180deg);
  }

  .cmp-contact-button-text {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
  }
}

.cmp-header__main {
  flex-flow: wrap;
  justify-content: space-between;
  padding: 8px;
  display: flex;
}

.cmp-header__logo {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.cmp-header__logo_container {
  flex-direction: row;
  align-items: flex-end;
  display: flex;
}

.cmp-header__logo_container--infra {
  width: 100px;
}

.cmp-header__logo_container--seprator {
  border: .1px solid #ccc;
  width: .2px;
  height: 60px;
  margin-left: 10px;
}

.cmp-header__logo_container--company {
  width: 190px;
}

.cmp-header__logo--title {
  color: #10304e;
  letter-spacing: 0;
  margin-left: 10px;
  font-family: Gotham-Bold;
  font-size: 16px;
}

.cmp-header__contact-button {
  color: #fff;
  cursor: pointer;
  background-color: #0c2d48;
  border-radius: 25px;
  padding: 8px 20px;
  font-family: Gotham-Medium;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
}

.cmp-header__contact-button:hover {
  color: #fff;
  background-color: #189ab4;
  box-shadow: 10px 10px 20px #0000001a;
}

.cmp-header__login {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.cmp-header__login--mail {
  color: #10304e;
  padding-right: 10px;
  font-family: Gotham-Medium;
  font-size: 16px;
  font-weight: 400;
}

.cmp-header__login .login-button, .cmp-header__login .login-close {
  color: #fff;
  cursor: pointer;
  background-color: #10304e;
  border: none;
  border-radius: 25px;
  height: 35px;
  margin: 8px 0;
  padding: 0 20px;
}

.cmp-header__login .login-close:hover, .cmp-header__login .login-button:hover {
  background-color: #0a418b;
  box-shadow: 10px 10px 20px #0000001a;
}

.cmp-header__hamburger-menu {
  display: none;
}

@media (width <= 767px) {
  .cmp-header__hamburger-menu {
    display: block;
  }

  .cmp-header__login {
    display: none;
  }

  .hamburger-menu__user-info {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    display: flex;
  }

  .hamburger-menu__user-info--username {
    color: #10304e;
    font-family: Gotham-Medium;
    font-size: 14px;
    font-weight: 400;
  }

  .hamburger-menu__user-info--logout {
    color: #fff;
    cursor: pointer;
    background-color: #10304e;
    border-radius: 25px;
    padding: 8px 16px;
  }

  .hamburger-menu__user-info--logout:hover {
    background-color: #0a418b;
    box-shadow: 10px 10px 20px #0000001a;
  }
}

@font-face {
  font-family: Open Sans;
  src: url("OpenSans-Regular.217d56b0.eot");
  src: url("OpenSans-Regular.217d56b0.eot#iefix") format("embedded-opentype"), url("OpenSans-Regular.f668acce.woff2") format("woff2"), url("OpenSans-Regular.1c5b7ef9.woff") format("woff"), url("OpenSans-Regular.90e42733.ttf") format("truetype"), url("OpenSans-Regular.9e4c4518.svg#OpenSans-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Gotham;
  src: url("Gotham-Book.00f78739.eot");
  src: url("Gotham-Book.00f78739.eot#iefix") format("embedded-opentype"), url("Gotham-Book.62f228a7.woff2") format("woff2"), url("Gotham-Book.ddeb2b20.woff") format("woff"), url("Gotham-Book.1bb42d45.ttf") format("truetype"), url("Gotham-Book.fabce4be.svg#svgFontName") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham-Medium.827c2da4.eot");
  src: url("Gotham-Medium.827c2da4.eot#iefix") format("embedded-opentype"), url("Gotham-Medium.86d73c2d.woff") format("woff"), url("Gotham-Medium.cd5f2e65.woff2") format("woff2"), url("Gotham-Medium.d772714b.ttf") format("truetype"), url("Gotham-Medium.2ac9cd84.svg#svgFontName") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Gotham-Bold;
  src: url("Gotham-Bold.32011e3e.eot");
  src: url("Gotham-Bold.32011e3e.eot#iefix") format("embedded-opentype"), url("gotham-bold-webfont.9bd79b73.woff2") format("woff2"), url("Gotham-Bold.91886102.woff") format("woff"), url("Gotham-Bold.615302b9.ttf") format("truetype"), url("Gotham-Bold.c5f92b1a.svg#Gotham-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

.card-header {
  border-bottom: 1px solid #ccc;
  padding: 8px 16px;
}

.card-header table.matrixHeaderTable {
  width: 100%;
}

.card-header table.matrixHeaderTable h2 {
  margin: 3px;
  font-size: 24px;
}

.card-header table.matrixHeaderTable p {
  margin: 3px;
  font-size: 12px;
}

.card-header table.matrixHeaderTable tbody tr td h6 {
  margin: 3px;
  font-size: 11px;
  font-weight: normal;
}

.card-header table.matrixHeaderTable tbody tr td p {
  margin: 3px;
  font-size: 12px;
  font-weight: normal;
}

.card-header table.matrixHeaderTable .nsebse {
  padding-left: 13px;
}

.card-header table.matrixHeaderTable .nsebse span {
  color: #696969;
  font-size: 14px;
}

.card-header table.matrixHeaderTable .nsebse span .live {
  color: #000;
  background-color: #dbec71;
  padding: 2px;
  font-size: 10px;
}

.card-header table.matrixHeaderTable .nsebse p {
  color: #2eae32;
  margin: 0 10px 4px 0;
  font-size: 26px;
  font-weight: bold;
}

.card-header table.matrixHeaderTable .nsebse p span {
  font-size: 12px;
  font-weight: normal;
}

.card-header table.matrixHeaderTable .officeListing p {
  font-size: 11px;
  font-weight: normal;
}

.card-body {
  padding: 8px 16px;
}

.card-body table.matrixHeaderTable {
  width: 100%;
}

.card-body table.matrixHeaderTable tbody tr td {
  width: 16%;
}

.card-body table.matrixHeaderTable tbody tr td h6 {
  margin: 3px;
  font-size: 11px;
  font-weight: normal;
}

.card-body table.matrixHeaderTable tbody tr td p {
  margin: 3px;
  font-size: 14px;
  font-weight: bold;
}

.card-body img {
  width: 100%;
  height: auto;
}

.card-footer {
  text-align: left;
  padding: 8px 16px;
}

.card-footer p {
  color: #696969;
  margin: 0;
  font-size: 12px;
}

html, body {
  height: 100%;
  margin: 0;
  font-family: Gotham;
}

.modal-backdrop {
  z-index: 9999;
}

.dashboardBoxes {
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 48%;
  height: 310px;
  box-shadow: 0 0 10px #0000001a;
  margin: 8px !important;
}

.dashboardBoxes:first-child {
  background-color: #e9f7ea;
}

.dashboardBoxes:nth-child(2) {
  background-color: #faf6e4;
}

.dashboardBoxes:nth-child(3) {
  background-color: #f7ecfd;
}

.dashboardBoxes:nth-child(4) {
  background-color: #fcf0e9;
}

.dashboardBoxes .card-header table tbody td {
  width: 50%;
}

.dashboardBoxes .card-header table tbody td h2 {
  margin: 0;
  font-size: 22px;
}

.dashboardBoxes .card-header table .HeaderSmall {
  font-size: 12px;
}

.dashboardBoxes .card-body table tbody tr.topLiveRate td {
  width: 50%;
}

.dashboardBoxes .card-body table tbody tr.topLiveRate td h4 {
  color: #4c51c9;
  margin: 16px 10px 4px 0;
  font-size: 14px;
}

.dashboardBoxes .card-body table tbody tr.topLiveRate td h4 span {
  color: #000;
  background-color: #dbec71;
}

.dashboardBoxes .card-body table tbody tr.BtmDetails td {
  width: 50%;
}

.dashboardBoxes .card-body table tbody tr.BtmDetails td h4 {
  color: #4c51c9;
  margin: 16px 10px 4px 0;
  font-size: 14px;
}

.dashboardBoxes .card-body table tbody tr.BtmDetails td h4 p {
  color: #121212;
  margin: 9px 0 4px 1px;
  font-size: 26px;
  font-weight: bold;
}

.dashboardBoxes .card-body table .AmountNseBse {
  color: #2eae32;
  margin: 10px 10px 4px 0;
  font-size: 26px;
  font-weight: bold;
}

.dashboardBoxes .card-body table .AmountNseBse span {
  font-size: 12px;
  font-weight: normal;
}

.dashboardBoxes .card-body table .HeaderSmall {
  font-size: 12px;
}

.dashboardBoxes .card-footer {
  padding: 9px 0 4px 10px;
}

.dashboardBoxes .card-footer button {
  color: #fff;
  background-color: #3338ae;
  border: 0 solid #000;
  border-radius: 3px;
  width: 120px;
  height: 30px;
  padding: 4px 16px;
  font-size: 14px;
}

.newsPanel {
  background-color: #dadbe5;
  width: 100%;
  height: 600px;
  padding: 10px;
}

.newsPanel h6 {
  border-bottom: 1px solid #fff;
  margin: 10px 0;
}

.titleFilter {
  justify-content: center;
  align-items: center;
  display: flex;
}

.titleFilter h4 {
  width: 50%;
  margin: 0;
  font-size: 20px;
  font-weight: 400;
}

.titleFilter .filterMain {
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  display: flex;
}

.titleFilter .filterMain span {
  vertical-align: middle;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0 5px;
  padding: 5px 10px;
}

.titleFilter .filterMain div .basic-multi-select .select__control {
  min-height: 30px;
}

.titleFilter .filterMain div .basic-multi-select .select__control .select__value-container {
  padding: 0 2px;
}

.titleFilter .filterMain div .basic-multi-select .select__control .select__value-container .select__multi-value {
  border-radius: 5px;
}

.titleFilter .filterMain div .basic-multi-select .select__control .select__value-container .select__multi-value .select__multi-value__label {
  font-size: 70%;
}

.titleFilter .filterMain div .basic-multi-select .select__control .select__indicators .select__indicator {
  padding: 4px;
}

.popup {
  z-index: 1000;
  background: #fff;
  border: 1px solid #ccc;
  width: 300px;
  padding: 20px;
  position: fixed;
  top: 44%;
  left: 84%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px #00000080;
}

.Toastify__toast-container {
  z-index: 200000;
}
/*# sourceMappingURL=index.3d0387de.css.map */
