@import '../../styles/shared/variables.less';

@cmp-name: ~'cmp-contact-button';

.@{cmp-name} {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: @space-cadet;
  color: #fff;
  border: none;
  border-radius: 50px;
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  z-index: 1000;

  &:hover {
    background-color: @btn-blue-hover;
    transform: translateY(-3px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.3);
  }
}

@media (max-width: 767px) {
  .@{cmp-name} {
    right: -2px;

    bottom: 50%;
    transform: translateY(50%);

    border-radius: 0;
    padding: 5px 3px;
    font-size: 14px;

    writing-mode: vertical-rl;
    transform: rotate(180deg);

    &-text {
      writing-mode: vertical-rl;
      transform: rotate(180deg);
    }
  }
}
