.container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 40px;
  text-align: center;
}

.pseudo-div {
  height: 200px;
}

header {
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.input {
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.button {
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  background-color: #10304e;
  color: #fff;
  cursor: pointer;
}
