@import '../../styles/shared/variables.less';
@cmp-name: ~'cmp-compare-adjacent';

.@{cmp-name} {
  display: flex;
  flex-direction: column;
  align-items: center;

  &_map {
    width: 98%;

    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }

    .graph_main {
      display: flex;
      flex-direction: row;

      @media only screen and (max-width: 767px) {
        flex-direction: column;
      }
      .graph_image {
        width: 70%;
        @media only screen and (max-width: 767px) {
          width: 100%;
        }
      }
      .graph_content {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;

        @media only screen and (max-width: 767px) {
          width: 100%;
          margin-top: 0;
        }
      }
    }
  }

  &_search {
    display: flex;
    //border-top: 0.5px solid rgb(204, 204, 204);
    //width: 100%;
    height: 30px;
    margin-bottom: 10px;
    padding: 12px;
  }

  &_main {
    width: 98%;
    &_header {
      width: 100%;
      &-title {
        font-family: @gotham-bold;
        background-color: #3abeb7;
        padding: 15px 10px;
        border-radius: 8px 8px 0 0;
      }
    }
    &_body {
      width: 100%;
    }
  }
}

// /* Style the buttons */
// .btn {
//   border: none;
//   outline: none;
//   padding: 10px 16px;
//   background-color: #f1f1f1;
//   cursor: pointer;
//   font-size: 18px;
// }

// /* Style the active class, and buttons on mouse-over */
// .navbar .active,
// .btn:hover {
//   background-color: rgb(233, 247, 234);
//   color: white;
// }

// /* Links inside the navbar */
// .navbar a,
// .navbar .btn {
//   float: left;
//   font-size: 16px;
//   color: rgb(0, 0, 0);
//   text-align: center;
//   padding: 14px 16px;
//   text-decoration: none;
// }

// /* The dropdown container */
// .dropdown {
//   float: left;
//   overflow: hidden;
// }

// /* Dropdown button */
// .dropdown .dropbtn {
//   font-size: 16px;
//   border: none;
//   outline: none;
//   color: rgb(0, 0, 0);
//   padding: 14px 16px;
//   background-color: inherit;
//   font-family: inherit; /* Important for vertical align on mobile phones */
//   margin: 0; /* Important for vertical align on mobile phones */
// }

// /* Add a green background color to navbar links on hover */
// .navbar a:hover,
// .dropdown:hover .dropbtn,
// .navbar .btn:hover {
//   background-color: rgb(23, 209, 162);
// }

// /* Dropdown content (hidden by default) */
// .dropdown-content {
//   display: none;
//   position: absolute;
//   background-color: #f9f9f9;
//   min-width: 160px;
//   box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
//   z-index: 1;
// }

// /* Links inside the dropdown */
// .dropdown-content .btn {
//   float: none;
//   color: black;
//   padding: 12px 16px;
//   text-decoration: none;
//   display: block;
//   text-align: left;
// }

// /* Add a grey background color to dropdown links on hover */
// .dropdown-content .btn:hover {
//   background-color: rgb(23, 209, 162);
// }

// /* Show the dropdown menu on hover */
// .dropdown:hover .dropdown-content {
//   display: block;
// }
