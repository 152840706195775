.tableHeader {
  span {
    background-color: #e9f7ea;
    color: #000;
    text-align: left;
    width: 100%;
  }
  .tableGrid {
    width: 98%;
    // height: 200px;
    overflow-x: auto;
    border-collapse: collapse;
    th {
      background-color: #dadbe5;
      height: 27px;
      padding: 10px;
      font-size: 12px;
      border: none;
      font-weight: normal;
      text-align: left;
    }
    .location-header td {
      font-weight: normal;
      color: #121212;
      background-color: #e9fcf9;
      text-align: left;
    }
    td {
      background-color: #fff;
      border-bottom: 1px solid #ccc;
      padding: 12px;
      font-size: 14px;
    }
  }
}
