@import '../../../../styles/shared/variables.less';

@cmp-name: ~'cmp-evaluation-table';

.@{cmp-name} {
  &__container {
    border-radius: 8px;
    background-color: white;
    border: 0.5px solid #c5ccd4;
    padding: 1rem;
    font-weight: normal;

    &_selector {
      width: 50%;
      margin-bottom: 16px;

      &-label {
        font-family: @gotham-bold;
        font-size: 16px;
        margin-right: 10px;
      }

      &-dropdown {
        font-size: 12px;
        padding: 5px;
        border-radius: 4px;
      }
    }

    &_static-table {
      &__tbl {
        width: 100%;
        border-collapse: collapse;
        box-sizing: border-box;
        border-radius: 4px;
        border: 0.5px solid #c5ccd4;
        table-layout: fixed;

        tbody > tr:first-child {
          background-color: @light-cyan;
        }

        tr {
          box-sizing: border-box;
          border-bottom: 0.5px solid #c5ccd4;
          padding: 10px;
          width: 50%;
        }

        td {
          padding: 10px;
          font-size: 12px;

          &:first-child {
            width: 35%;
          }

          &:last-child {
            font-family: @gotham-medium;
          }
        }
      }
    }
  }

  &__discout {
    &-header {
      background-color: @max-blue-green;
      padding: 20px;
      box-sizing: border-box;
      font-size: 1rem;
      margin: 20px 0px;
      border-radius: @border-radius-8;

      &-txt {
        font-family: @gotham-bold;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  &__boxes {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    @media only screen and (min-width: 100px) and (max-width: 599px) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      flex-direction: column;
      gap: 10px;

      &-modal {
        width: 85%;
        height: 200px;
      }
    }

    &-modal {
      height: 161px;
      width: 30%;
      border-radius: 8px;
      border: 1px solid #c5ccd4;
      padding: 12px;

      @media only screen and (min-width: 100px) and (max-width: 599px) {
        width: 85%;
      }

      &-header {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 8px;
        background-color: #e9fcf9;

        &-txt {
          font-family: @gotham-bold;
          font-size: 16px;
          font-weight: 600;
        }
      }

      &-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 14px 16px;
        gap: 30px;
        font-family: @gotham-medium;

        &-txt {
          font-size: 16px;
          font-weight: 600;
        }

        &-value {
          font-size: 16px;
          font-weight: 600;

          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;

          img {
            height: 14px;
            cursor: @cursor-pointer;
          }

          img:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__disclaimer {
    margin-top: 12px;
    font-size: 14px;
    font-weight: 500;

    p {
      margin: 2px;

      b {
        font-family: @gotham-medium;
      }
    }
  }
}
