@font-face {
  font-family: 'Open Sans';
  src: url('../../assets/fonts/OpenSans-Regular.eot');
  src:
    url('../../assets/fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/OpenSans-Regular.woff2') format('woff2'),
    url('../../assets/fonts/OpenSans-Regular.woff') format('woff'),
    url('../../assets/fonts/OpenSans-Regular.ttf') format('truetype'),
    url('../../assets/fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('../../assets/fonts/Gotham-Medium/Gotham-Book.eot');
  src:
    url('../../assets/fonts/Gotham-Medium/Gotham-Book.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/Gotham-Medium/Gotham-Book.woff2') format('woff2'),
    url('../../assets/fonts/Gotham-Medium/Gotham-Book.woff') format('woff'),
    url('../../assets/fonts/Gotham-Medium/Gotham-Book.ttf') format('truetype'),
    url('../../assets/fonts/Gotham-Medium/Gotham-Book.svg#svgFontName') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham-Medium';
  src: url('../../assets/fonts/Gotham-Medium/Gotham-Medium.eot');
  src:
    url('../../assets/fonts/Gotham-Medium/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/Gotham-Medium/Gotham-Medium.woff') format('woff'),
    url('../../assets/fonts/Gotham-Medium/Gotham-Medium.woff2') format('woff2'),
    url('../../assets/fonts/Gotham-Medium/Gotham-Medium.ttf') format('truetype'),
    url('../../assets/fonts/Gotham-Medium/Gotham-Medium.svg#svgFontName') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham-Bold';
  src: url('../../assets/fonts/Gotham-Medium/Gotham-Bold.eot');
  src:
    url('../../assets/fonts/Gotham-Medium/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/Gotham-Medium/gotham-bold-webfont.woff2') format('woff2'),
    url('../../assets/fonts/Gotham-Medium/Gotham-Bold.woff') format('woff'),
    url('../../assets/fonts/Gotham-Medium/Gotham-Bold.ttf') format('truetype'),
    url('../../assets/fonts/Gotham-Medium/Gotham-Bold.svg#Gotham-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
