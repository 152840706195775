@import '../../../../../styles/shared/variables.less';
.risk-premium {
  //padding: 10px;
  //background-color: #f9f9f9;
  //border: 1px solid #ccc;
  //border-radius: 8px;
}

.dropdown-section {
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}

.dropdown-section label {
  display: block;
  font-family: @gotham-bold;
}

// .dropdown-section select {
//   width: 59%    ;
//   padding: 8px;
//   //margin-bottom: 16px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
// }

.risk-premium-value {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #1a73e8;
  margin-top: 16px;
}

.add-risk-premium {
  display: block;
  //width: 100%;
  padding: 12px;
  background-color: @bubbles;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: @gotham-bold;
}

//   .add-risk-premium:hover {
//     background-color: #1558b0;
//   }

.risk-premium-header {
  background-color: #dadbe5;
  padding: 12px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px 8px 0 0;
}
