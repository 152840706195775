@import '../../../../styles/shared/variables.less';

@cmp-name: ~'cmp-wacc-flow';

.@{cmp-name} {
  overflow-x: auto;
  width: 98%;

  &__navbar {
    width: 100%;
    height: 42px;
    border-bottom: 1px solid;
    display: flex;
    flex-direction: row;

    &-btn {
      font-family: @gotham-medium;
      border: @display-none;
      outline: @display-none;
      padding: 10px 16px;
      cursor: @cursor-pointer;
      font-size: @font-size-16;
      background-color: @white;

      &:hover {
        border-bottom: 3px @border-solid @blue;
        background-color: #e9f7ea;
      }

      &--active {
        font-family: @gotham-bold;
        border: @display-none;
        outline: @display-none;
        padding: 10px 16px;
        cursor: @cursor-pointer;
        font-size: @font-size-16;
        background-color: @white;
        border-bottom: 3px @border-solid @blue;
        color: @max-blue-green;
      }
    }
  }

  &__body {
    font-size: @font-size-12;
    margin-top: 10px;
    width: 100%;

    &-panel {
      display: flex;
      flex-direction: column;

      &--model {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 10px;

        &--risk {
          padding: 10px;
          width: 50%;
          border: 0.5px solid #ccc;
          border-radius: 8px;
        }

        &--cost {
          padding: 10px;
          width: 50%;
          border: 0.5px solid #ccc;
          border-radius: 8px;
        }
      }

      &--rate {
        padding: 10px;
        border: 0.5px solid #ccc;
        margin-top: 10px;
        border-radius: 8px;

        &--txt {
          font-family: @gotham-bold;
          padding: 16px;
          display: flex;
          justify-content: center;
          //border: 0.5px solid #ccc;
          border-radius: 8px;
          background-color: #10304e;
          color: white;
        }

        &--value {
          text-align: center;
          color: #1a73e8;
          margin-top: 16px;
          font-size: 24px;
          font-weight: bold;
        }
      }
    }
  }

  &__control {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;

    &--cancel {
      font-size: 14px;
      margin: 10px;
      background-color: white;
      border: 0.5px solid #3338ae;
      padding: 8px;
      border-radius: 3px;

      &:hover {
        background-color: @midnight-blue;
        color: white;
        font-weight: 600;
      }
    }

    &--back {
      font-size: 14px;
      margin: 10px;
      background-color: white;
      border: 0.5px solid #3338ae;
      padding: 8px;
      border-radius: 3px;

      &:hover {
        background-color: @midnight-blue;
        color: white;
        font-weight: 600;
      }
    }

    &--calculate {
      font-size: 14px;
      margin: 10px;
      background-color: @space-cadet;
      border: 0.5px solid #3338ae;
      padding: 8px;
      color: white;
      font-weight: 600;
      border-radius: 3px;

      &:hover {
        background-color: @midnight-blue;
        color: white;
      }
    }
  }
}
